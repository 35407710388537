import VueRouter from "vue-router";
import Landing from "./components/Landing";
import Vue from "vue";
import About from "./components/About";
import Career from "./components/Career";
import Referral from "./components/Referral";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import TermsConditionsReferral from "./components/TermsConditionsReferral";
import PaymentSuccess from "./components/PaymentSuccess.vue";
import PaymentCancel from "./components/PaymentCancel.vue";
import SupportUs from "./components/SupportUs.vue";
// import Calculator from "./components/Calculator.vue";

Vue.use(VueRouter);

const routes = [
    { path: "/", name: 'home', component: Landing },
    { path: "/about", name: 'about', component: About },
    { path: "/career", name: 'career', component: Career },
    { path: "/ilovetcb", name: 'supportUs', component: SupportUs },
    { path: "/payment-success", name: 'paymentSuccess', component: PaymentSuccess },
    { path: "/payment-cancel", name: 'paymentCancel', component: PaymentCancel },
    { path: "/referral", name: 'referral', component: Referral },
    { path: "/privacy-policy", name: 'privacyPolicy', component: PrivacyPolicy },
    { path: "/terms-of-service", name: 'termsOfService', component: TermsOfService },
    { path: "/terms-conditions-referral", name: 'termsConditionsReferral', component: TermsConditionsReferral },
    // { path: "/calc", name: 'calculator', component: Calculator },
];

const router = new VueRouter({
    // mode: 'history',
  routes,
});

export default router;
