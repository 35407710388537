<template>
  <div>
    <div class="landing-header blue lighten-5">
      <div class="arrow-vector">
        <div class="arrow-vector-inner">
          <div class="background-shapes__circle-filled"></div>
          <!--          <div class="arrow-shape">-->
          <!--            <img src="../assets/arrow.svg" alt=""/>-->
          <!--          </div>-->
        </div>
      </div>
      <v-container class="py-0 landing-header-inner">
        <v-row>
          <v-col
              cols="12"
              md="12"
              lg="12"
              class="d-flex align-center justify-center flex-column"
          >
            <div class="pt-6 px-4 pa-md-0">
              <h2 class="font-weight-black mb-3">
                <span class="d-inline-block animate-text font-weight-black">Support The Coffee Break Newsletter</span>
              </h2>
              <p
                  class="font-weight-regular blue-grey--text text--darken-4 headline"
              >

              </p>

              <div>
                <p>Thanks for dropping by and considering to contribute towards our Tabung Kopi.</p>

                <p>Our mission is to bring the joy and ease of learning to everyone and to help Malaysians navigate the
                  world with clarity.
                </p>

                <p>Your contribution will allow us to continue crafting the newsletter in the long run, making many
                  others informed and save something money can’t buy - time.
                </p>

                <p>
                  If you are able to make a contribution, no matter how small, it would be greatly appreciated. It will
                  help us to continue to grow and improve our newsletter in the future!

                </p>
                <v-card
                    class="my-6 pa-2"
                >
                  <div class="pt-2 text-center">
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                            outlined
                            value="oneTime"
                            :class="supportBtnToggle === 'oneTime' ? 'btn-selected': ''"
                            @click="() => supportBtnToggle = 'oneTime'">
                          One Time
                        </v-btn>
                        <v-btn
                            outlined
                            value="monthly"
                            :class="supportBtnToggle === 'monthly' ? 'btn-selected': ''"
                            @click="() => supportBtnToggle = 'monthly'">
                          Monthly
                        </v-btn>
                        <v-btn
                            outlined
                            value="annual"
                            :class="`${supportBtnToggle === 'annual' ? 'btn-selected': ''} btn--wiggle`"
                            @click="() => supportBtnToggle = 'annual'">
                          Annual
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-card-text>
                      <div v-if="supportBtnToggle==='monthly'">
                        <h3 class="mt-4">
                        <span class="d-inline-block animate-text font-weight-black">
                          <span style="font-size:48px; line-height: 1;">RM9 / month</span>
                        </span>
                        </h3>
                      </div>
                      <div v-if="supportBtnToggle==='annual'">
                        <div class="mt-4">
                        <span class="d-inline-block animate-text font-weight-black">
                          <span style="font-size:48px; line-height: 1;">RM99 / year</span>
                        </span>
                        </div>
                      </div>

                      <h4 class="support-text">
                        {{ supportText }}
                      </h4>
                      <span v-if="supportBtnToggle==='oneTime'">
                        <small>Enter contribution amount on next page</small>
                      </span>

                      <v-row>
                        <v-col cols="12">
                          <v-btn
                              :loading="loading"
                              block
                              elevation="5"
                              color="blue darken-4"
                              class="white--text mt-12"
                              x-large
                              @click="supportTcb"
                          >
                            Support Us @ {{ supportMoney }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>

                  </div>
                </v-card>
                <!--                <v-row>-->
                <!--                  <v-col cols="12"-->
                <!--                         md="12"-->
                <!--                         lg="4">-->
                <!--                    <v-card-->
                <!--                        :loading="loading"-->
                <!--                        class="my-lg-6"-->
                <!--                    >-->
                <!--                      <v-card-title>One Time Support</v-card-title>-->

                <!--                      <v-card-text>-->
                <!--                        <div class="text-subtitle-1">-->
                <!--                          &lt;!&ndash;                          $ • RM99 annually&ndash;&gt;-->
                <!--                        </div>-->

                <!--                        <div>No commitment! Contribute when you need to.</div>-->
                <!--                      </v-card-text>-->

                <!--                      &lt;!&ndash;                      <v-divider class="mx-4"></v-divider>&ndash;&gt;-->

                <!--                      &lt;!&ndash;                      <v-card-title>Tolong Tolong</v-card-title>&ndash;&gt;-->

                <!--                      &lt;!&ndash;                      <v-card-text>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                        <div>Lorem ipsum dolor sit amet</div>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                      </v-card-text>&ndash;&gt;-->

                <!--                      <v-card-actions>-->
                <!--                        <v-btn-->
                <!--                            color="blue darken-4"-->
                <!--                            class="white&#45;&#45;text d-none d-block"-->
                <!--                            style="border-width: 2px"-->
                <!--                            @click="submitOneTimeSupport"-->
                <!--                        >-->
                <!--                          Support Us-->
                <!--                        </v-btn>-->
                <!--                      </v-card-actions>-->
                <!--                    </v-card>-->
                <!--                  </v-col>-->
                <!--                  <v-col cols="12"-->
                <!--                         md="12"-->
                <!--                         lg="4">-->
                <!--                    <v-card-->
                <!--                        :loading="monthLoading"-->
                <!--                        class="my-lg-6"-->
                <!--                    >-->
                <!--                      <v-card-title>RM8.90 / month</v-card-title>-->
                <!--                      <v-card-text>-->
                <!--                        <div class="text-subtitle-1">-->

                <!--                        </div>-->
                <!--                        <div>Save hours at the price of one latte a month.</div>-->
                <!--                      </v-card-text>-->
                <!--                      &lt;!&ndash;                      <v-divider class="mx-4"></v-divider>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                      <v-card-title>Tolong Tolong</v-card-title>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                      <v-card-text>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                        <div>Lorem ipsum dolor sit amet</div>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                      </v-card-text>&ndash;&gt;-->
                <!--                      <v-card-actions>-->
                <stripe-checkout
                    ref="checkoutMonthRef"
                    mode="subscription"
                    :pk="mmtvPublishableKey"
                    :line-items="lineItems.production['monthly']"
                    :success-url="successURL"
                    :cancel-url="cancelURL"
                    @loading="t => monthLoading = t"
                />
                <!--                        <v-btn-->
                <!--                            color="blue darken-4"-->
                <!--                            class="white&#45;&#45;text d-none d-block"-->
                <!--                            style="border-width: 2px"-->
                <!--                            @click="submitMonth"-->
                <!--                        >-->
                <!--                          Support Us-->
                <!--                        </v-btn>-->
                <!--                      </v-card-actions>-->
                <!--                    </v-card>-->
                <!--                  </v-col>-->
                <!--                  <v-col cols="12"-->
                <!--                         md="12"-->
                <!--                         lg="4">-->
                <!--                    <v-card-->
                <!--                        :loading="annualLoading"-->
                <!--                        class="my-lg-6"-->
                <!--                    >-->
                <!--                      <v-card-title>RM99 / year</v-card-title>-->
                <!--                      <v-card-text>-->
                <!--                        <div class="text-subtitle-1">-->
                <!--                          &lt;!&ndash;                          $ • RM99 annually&ndash;&gt;-->
                <!--                        </div>-->
                <!--                        <div>Best value for money. Hundreds of hours saved.</div>-->
                <!--                      </v-card-text>-->
                <!--                      &lt;!&ndash;                      <v-divider class="mx-4"></v-divider>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                      <v-card-title>Tolong Tolong</v-card-title>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                      <v-card-text>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                        <div>Lorem ipsum dolor sit amet</div>&ndash;&gt;-->
                <!--                      &lt;!&ndash;                      </v-card-text>&ndash;&gt;-->
                <!--                      <v-card-actions>-->
                <stripe-checkout
                    ref="checkoutAnnualRef"
                    mode="subscription"
                    :pk="mmtvPublishableKey"
                    :line-items="lineItems.production['yearly']"
                    :success-url="successURL"
                    :cancel-url="cancelURL"
                    @loading="v => annualLoading = v"
                />
                <!--                        <v-btn-->
                <!--                            color="blue darken-4"-->
                <!--                            class="white&#45;&#45;text d-none d-block btn&#45;&#45;wiggle"-->
                <!--                            style="border-width: 2px"-->
                <!--                            @click="submitAnnual"-->
                <!--                        >-->
                <!--                          Support Us-->
                <!--                        </v-btn>-->
                <!--                      </v-card-actions>-->
                <!--                    </v-card>-->
                <!--                  </v-col>-->
                <!--                </v-row>-->

                <!--                <stripe-checkout-->
                <!--                    ref="checkoutRef"-->
                <!--                    mode="subscription"-->
                <!--                    :pk="publishableKey"-->
                <!--                    :line-items="lineItems"-->
                <!--                    :success-url="successURL"-->
                <!--                    :cancel-url="cancelURL"-->
                <!--                    @loading="v => loading = v"-->
                <!--                />-->
                <!--                <v-btn-->
                <!--                    color="blue darken-4"-->
                <!--                    class="white&#45;&#45;text mt-5 mr-1 d-none d-md-block"-->
                <!--                    style="border-width: 2px"-->
                <!--                    @click="submit"-->
                <!--                >-->
                <!--                  Support Us-->
                <!--                </v-btn>-->
              </div>
              <br>
              <hr>
              <br>
              <div>
                <h1 class="font-weight-black mb-3">
                  <span class="d-inline-block animate-text font-weight-black">FAQ</span>
                </h1>
                <div>
                  <p>
                    <b>1. Will I still receive the newsletter if I don’t contribute?</b>
                    <br>
                    Yes, you will still receive all issues. We are not putting up a subscription plan, but rather a
                    voluntary contribution plan for our avid readers to support us.
                  </p>

                  <p>
                    <b>2. Am I getting anything special or extra from contributing?</b>
                    <br>
                    Your contribution will greatly support our mission to provide valuable and informative content to
                    all our readers. #KitaJagaKita. That’s the most special thing you will get. We do have future plans
                    to give out merch and we won’t forget you!
                  </p>

                  <p>
                    <b>3. Is your payment method safe and secure?</b>
                    <br>
                    We are using Stripe – a global payment processing company. The company counts Grab, Jaya Grocer,
                    Zoom amongst its customers. All is good! Here is our <a href="/#/privacy-policy">privacy
                    statement</a>.
                  </p>

                  <p>
                    <b>4. How do I update or cancel my monthly / annual subscription?</b>
                    <br>
                    Click on the 'Manage Account' button on the navigation bar on top and login to Stripe and proceed
                    with the modification or cancellation.
                  </p>
                </div>
              </div>


              <!--              <div class="my-2">-->
              <!--                <a-->
              <!--                    href="https://www.facebook.com/breakmycoffee"-->
              <!--                    target="_blank"-->
              <!--                    class="btn-sns btn-facebook"-->
              <!--                >-->
              <!--                  <v-icon class="me-2" color="white"> mdi-facebook</v-icon>-->
              <!--                  <span>The Coffee Break</span>-->
              <!--                </a>-->
              <!--                <a-->
              <!--                    href="https://twitter.com/BreakMyCoffee"-->
              <!--                    target="_blank"-->
              <!--                    class="btn-sns btn-twitter"-->
              <!--                >-->
              <!--                  <v-icon class="me-2" color="white">mdi-twitter</v-icon>-->
              <!--                  <span>@breakmycoffee</span>-->
              <!--                </a>-->
              <!--              </div>-->
            </div>
          </v-col>
          <!--          <v-col cols="12" md="5" lg="6">-->
          <!--            <div class="phone">-->
          <!--              <img src="../assets/phone.png" alt=""/>-->
          <!--            </div>-->
          <!--          </v-col>-->
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$leading: (
    "none": 1,
    "tight": 1.25,
    "snug": 1.375,
    "normal": 1.5,
    "relaxed": 1.625,
    "loose": 2,
);

.testi-links {
  color: #00d4ff;
}

.support-text {
  font-size: 18px;
}

.btn-selected {
  background-color: #017cb7;
  color: white;
}

.no-uppercase {
  text-transform: unset !important;
}

.v-application .caption {
  font-size: medium !important;
}

@each $name, $value in $leading {
  .leading-#{$name} {
    line-height: $value !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1185px !important;
  }
}

.v-card__text__height {
  line-height: 1.5em;
  min-height: 7em;
}

.v-card__title {
  word-break: break-word;
}

.content-card {
  word-break: keep-all;

  figure {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.05) linear-gradient(
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 20%,
              rgba(0, 0, 0, 0.85) 100%
      );
      transition-property: all;
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
  }

  &:hover {
    figure {
      &:after {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.landing-header {
  position: relative;
  overflow: hidden;

  &-inner {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 58px;
    line-height: 1;
    @media only screen and (min-width: 600px) {
      font-size: 64px;
      line-height: 1.2;
    }
  }

  //p {
  //font-size: 16px;
  //}

  .animate-text {
    background: linear-gradient(231deg, #083d73, #094879, #00d4ff);
    background-size: 600% 600%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-animation: TextGradientAnimation 5s ease infinite;
    -moz-animation: TextGradientAnimation 5s ease infinite;
    animation: TextGradientAnimation 5s ease infinite;
  }
}

.arrow-vector {
  position: absolute;
  width: 100%;
  max-width: 1185px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  &-inner {
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
    display: grid;
    grid-column: 1/-1;
    grid-row: 1/-1;
    grid-template-areas:
      "topleft topcenter topright"
      "middleleft middlecenter middleright"
      "bottomleft bottomcenter bottomright";
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .arrow-shape {
    position: absolute;
    width: 500px;
    bottom: 0;
    z-index: 1;
    transform: rotate(-15deg);
    right: 5px;

    @media only screen and (min-width: 600px) {
      width: 1000px;
      right: 15px;
    }
  }

  .background-shapes__circle-filled {
    background-color: #0093e9;

    background-image: linear-gradient(
            160deg,
            #0093e9 0%,
            rgba(99, 251, 215, 1) 50%,
            #80d0c7 100%
    );
    mix-blend-mode: multiply;

    border-radius: 100%;
    position: relative;
    height: 590px;
    width: 590px;
    transform: translate3d(-30%, -10%, 0);
    grid-area: bottomcenter;
    @media only screen and (min-width: 600px) {
      height: 1090px;
      width: 1090px;
      transform: translate3d(-25%, -20%, 0);
      grid-area: bottomright;
    }
  }
}

.phone {
  max-width: 230px;
  margin: 30px auto;

  img {
    display: block;
    width: 100%;
  }

  @media only screen and (min-width: 600px) {
    max-width: 300px;
    transform: translateY(15%);
    margin: 0 auto;
  }
}

@-webkit-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@-moz-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

.section {
  &__title {
    display: flex;
    align-items: center;
    margin: 24px 0 20px;

    .icon {
      //width: 48px;
      //height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      margin-right: 6px;

      svg {
        display: block;
        width: 36px;
        height: 36px;
        fill: currentColor;
      }
    }
  }

  &-overview {
    text-align: center;

    p {
      max-width: 700px;
      margin: 0 auto 18px;
      font-size: 16px;
      line-height: 1.6;
      @media (min-width: 768px) {
        font-size: 21px;
        margin: 0 auto 24px;
      }
    }
  }

  &-top {
    margin: 30px 0;
    background-image: linear-gradient(
            0deg,
            #fff 0%,
            rgba(179, 229, 252, 1) 60%,
            #fff 100%
    );
  }
}

.newsletter-form {
  position: relative;
  max-width: 400px;

  .btn-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: calc(0.4rem * 0.75) 12px !important;
    height: auto !important;
  }

  .input {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(0.4rem * 0.75) calc(0.875rem * 0.5);
      margin: calc(0.4rem * 0.75 + 3px) calc(0.5rem * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: #e3f2fd;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 2px solid currentColor;
      padding: calc(0.4rem * 1.5) 0.875rem;
      padding-right: 100px;
      color: currentColor;
      background: #e3f2fd;
      border-radius: 5px;

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.2rem, -65%) scale(0.8);
          color: #1976d2;
        }
      }
    }
  }
}

// substack iframe
.iframe-container {
  &-header {
    position: relative;
    display: inline-flex;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: #e3f2fd;
    }

    &:before {
      left: 0;
      width: 12px;
      height: 100%;
      @media (min-width: 768px) {
        width: 17px;
      }
    }

    &:after {
      right: 0;
      width: 17px;
      height: 100%;
    }

    .top,
    .bottom {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      background: #e3f2fd;
    }

    .top {
      top: 0;
      height: 16px;
    }

    .bottom {
      bottom: 0;
      height: 32px;
    }
  }
}

.sns {
  width: 100%;
  //max-width: 700px;
  margin: 32px auto 0;
  padding: 32px 16px;
  background: rgba(0, 0, 0, 0.1);
  //border-top: 1px solid rgba(0, 0, 0, .2);
  //border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.btn-sns {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  line-height: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  font-size: 16px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  margin: 5px 0;
  transition: opacity 0.3s;
}

.btn-sns:hover {
  opacity: 0.9;
}

.btn-facebook {
  background: rgb(24, 119, 242);
  margin-right: 10px;
}

.btn-twitter {
  background: #1da1f2;
}

.btn--wiggle {
  animation: 1s wiggle ease infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(-3deg);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
  }
  20% {
    transform: rotate(1deg);
  }
  40% {
    transform: rotate(-3deg);
  }
  60% {
    transform: rotate(1deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
  }
}
</style>

<script>
import {StripeCheckout} from "@vue-stripe/vue-stripe";

export default {
  name: "SupportUs",
  components: {
    StripeCheckout,
  },
  mounted() {
    document.title = "Support Us | The Coffee Break";
  },
  watch: {
    supportBtnToggle(newValue) {
      if (newValue === 'oneTime') {
        this.supportText = 'No commitment! Contribute when you need to.';
        this.supportMoney = '';
      }
      if (newValue === 'monthly') {
        this.supportText = 'Save hours at the price of one latte a month.';
        this.supportMoney = 'RM 9 / month';
      }
      if (newValue === 'annual') {
        this.supportText = 'Best value for money. Hundreds of hours saved.';
        this.supportMoney = 'RM 99 / year';
      }
    },
  },
  data() {
    return {
      supportMoney: 'RM 9 / month',
      supportBtnToggle: 'monthly',
      supportText: 'Save hours at the price of one latte a month.',
      publishableKey: 'pk_live_51ND2p2FwRiGCw5FZlpT4SolKJ3xuGD5S3G36tvkHx1Vs4KcaNTBnXq8aQ4UQzK41iCo8m3SIsf7iQGMoMC4SyUCt00amm9AH9O',
      mmtvPublishableKey: 'pk_live_51Q0M0hDUg5L3GbTYYBFfF5OdciPmYk08aocfuicVWOAmYg1jXrHCQY1D3Z7pWBqknFmHkmcdaGoMBEirHe9a9gjs00zZWF4JXl',
      loading: false,
      monthLoading: false,
      annualLoading: false,
      lineItems: {
        test: {
          oneTime: [{
            price: 'price_1Mt6KuDD3uDbNo1DCt6XYfxa',
            quantity: 1,
          }],
          monthly: [{
            price: 'price_1Mt5flDD3u1DbNo1DV4KdVpoK',
            quantity: 1,
          }],
          yearly: [{
            price: 'price_1Mt5izDD3uDbNo1D9Haj1Gjm',
            quantity: 1,
          }]
        },
        tcbProduction: {
          oneTime: [
            { price: 'price_1N9f3kDD3uDbNo1DAQeYuCRk', quantity: 1 }
          ],
          monthly: [
            { price: 'price_1ND3FRFwRiGCw5FZh3DG9BPt', quantity: 1 }
          ],
          yearly: [
            { price: 'price_1ND3FpFwRiGCw5FZAnWpW705', quantity: 1 }
          ]
        },
        production: {
          oneTime: [
            { price: 'price_1N9f3kDD3uDbNo1DAQeYuCRk', quantity: 1 }
          ],
          monthly: [
            { price: 'price_1Q21LHDUg5L3GbTYg5jLucTc', quantity: 1 }
          ],
          yearly: [
            { price: 'price_1Q11vGDUg5L3GbTYO65QYBGb', quantity: 1 }
          ]
        }
      },
      successURL: window.location.origin + '/#/payment-success',
      cancelURL: window.location.origin + '/#/payment-cancel',
    };
  },
  methods: {
    // onClickDonation() {
    //   const url = 'https://billing.stripe.com/p/login/test_7sI8zQb50ckqgqQ9AA';
    //   window.open(url, '_blank', 'noreferrer');
    // },
    supportTcb() {
      this.$gtag.event('support', {
        'event_label': 'support',
        'value': this.supportBtnToggle,
      });

      if (this.supportBtnToggle === 'annual') {
        this.loading = true;
        this.submitAnnual();
      }
      if (this.supportBtnToggle === 'monthly') {
        this.loading = true;
        this.submitMonth();
      }
      if (this.supportBtnToggle === 'oneTime') {
        this.submitOneTimeSupport();
      }
    },
    submitMonth() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutMonthRef.redirectToCheckout();
    },
    submitAnnual() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutAnnualRef.redirectToCheckout();
    },
    submitOneTimeSupport() {
      // window.location.href = 'https://buy.stripe.com/test_aEUbLp4ww6eK4wgfYY';
      // this.$refs.checkoutOneTimeRef.redirectToCheckout();
      // window.open('https://donate.stripe.com/fZe9BEc4Lb3I2tydQQ', '_blank'); //TCB
      window.open('https://donate.stripe.com/00g9C35je1471osaEE', '_blank');

    },
  },
};
</script>
