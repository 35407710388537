<template>
  <div>
    <div class="landing-header blue lighten-5">
      <div class="arrow-vector">
        <div class="arrow-vector-inner">
          <div class="background-shapes__circle-filled"></div>
          <div class="arrow-shape">
            <img src="../assets/arrow.svg" alt=""/>
          </div>
        </div>
      </div>
      <v-container class="py-0 landing-header-inner">
        <v-row>
          <v-col
              cols="11"
              md="7"
              lg="6"
              class="d-flex align-center justify-center flex-column"
          >
            <div class="pt-6 px-4 pa-md-0">
              <h1 class="font-weight-black mb-3">
                <span class="d-inline-block animate-text font-weight-black"
                >About Us</span
                >
              </h1>
              <p
                  class="font-weight-regular blue-grey--text text--darken-4 headline"
              >
                We are The Coffee Break. <br/><br/>
                We started this newsletter with a simple idea in mind:
                <b>Making people more informed</b>
                <br/>
              </p>
              <div class="my-2">
                <a href="https://www.facebook.com/breakmycoffee" target="_blank" class="btn-sns btn-facebook">
                  <v-icon
                      class="me-2"
                      color="white"
                  >
                    mdi-facebook
                  </v-icon>
                  <span>The Coffee Break</span>
                </a>
                <a href="https://twitter.com/BreakMyCoffee" target="_blank" class="btn-sns btn-twitter">
                  <v-icon
                      class="me-2" color="white">mdi-twitter
                  </v-icon>
                  <span>@breakmycoffee</span>
                </a>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="5" lg="6">
            <div class="phone">
              <img src="../assets/phone.png" alt=""/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="section-overview mt-8 mt-md-14 mb-8">
      <v-container>
        <p class="blue-grey--text font-weight-black">
          In the information age, it is easy to get overwhelmed by the array of
          news. The team reads a lot of news every day because of the nature of
          our work — in banking & technology.
        </p>
        <p>
          With that, we figured there must be a better way for people to read
          the news other than skimming through multiple news portals.
        </p>
        <p>
          We started experimenting with our friends by sending the newsletter
          via Gmail during the early days. With their encouraging words, we
          decided to start the newsletter. As they say, the rest is history!
        </p>
        <!--          <span style="opacity: 0.6">-->
        <!--            We filter the news to take out the noise and at the same time, our-->
        <!--            readers can know and learn more.-->
        <!--          </span>-->
        <!--        <p class="" style="opacity: 0.6">-->
        <!--          We cover interesting facts, local and global news all in our daily-->
        <!--          newsletter.-->
        <!--        </p>-->
        <p>
          Our 5-days-a-week newsletter aims to make people stay informed, with
          both local Malaysian and global news.
          <br/>
          If you want to be informed, subscribe to The Coffee Break.
        </p>
        <p>We hope to see you in your inbox!</p>
        <p>
          You won’t have to worry about missing anything. Every new edition of
          the newsletter goes directly to your inbox. If they don’t, it could be
          in your Spam folder or Promotions tab (Gmail users) — move them to
          your Primary inbox.
        </p>
        <div class="sns">
          <p>Thank you for reading. If you don’t like getting emails, follow us on
            our social media.</p>
          <a href="https://www.facebook.com/breakmycoffee" target="_blank" class="btn-sns btn-facebook">
            <v-icon
                class="me-2"
                color="white"
            >
              mdi-facebook
            </v-icon>
            <span>The Coffee Break</span>
          </a>
          <a href="https://twitter.com/BreakMyCoffee" target="_blank" class="btn-sns btn-twitter">
            <v-icon
                class="me-2" color="white">mdi-twitter
            </v-icon>
            <span>@breakmycoffee</span>
          </a>
        </div>
      </v-container>
    </div>

    <div class="section-latest">
      <v-container>
        <v-row>
          <v-col cols="12" class="py-0">
            <h1 class="section__title mx-auto mb-3">
              <div class="icon">
                <svg
                    class="blue-grey--text text--lighten-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M21,10H3a1,1,0,0,0-1,1,10,10,0,0,0,5,8.66V21a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V19.66A10,10,0,0,0,22,11,1,1,0,0,0,21,10Zm-5.45,8.16a1,1,0,0,0-.55.9V20H9v-.94a1,1,0,0,0-.55-.9A8,8,0,0,1,4.06,12H19.94A8,8,0,0,1,15.55,18.16Z"
                      fill="black"
                  ></path>
                  <path
                      d="M9,9V7.93A4.53,4.53,0,0,0,7.72,4.78,2.49,2.49,0,0,1,7,3V2H5V3A4.53,4.53,0,0,0,6.28,6.17,2.49,2.49,0,0,1,7,7.93V9Z"
                      fill="currentColor"
                  ></path>
                  <path
                      d="M13,9V7.93a4.53,4.53,0,0,0-1.28-3.15A2.49,2.49,0,0,1,11,3V2H9V3a4.53,4.53,0,0,0,1.28,3.15A2.49,2.49,0,0,1,11,7.93V9Z"
                      fill="currentColor"
                  ></path>
                  <path
                      d="M17,9V7.93a4.53,4.53,0,0,0-1.28-3.15A2.49,2.49,0,0,1,15,3V2H13V3a4.53,4.53,0,0,0,1.28,3.15A2.49,2.49,0,0,1,15,7.93V9Z"
                      fill="currentCOlor"
                  ></path>
                </svg>
              </div>
              <span class="font-weight-black red--text"> Latest </span>
            </h1>
          </v-col>
          <template v-if="!rssFeeds">
            <v-col cols="6" md="4" lg="3">
              <v-skeleton-loader
                  :boilerplate="true"
                  elevation="2"
                  type="card-avatar, article, actions"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="6" md="4" lg="3">
              <v-skeleton-loader
                  :boilerplate="true"
                  elevation="2"
                  type="card-avatar, article, actions"
              ></v-skeleton-loader>
            </v-col>
          </template>
          <template v-if="rssFeeds">
            <v-col
                cols="6"
                md="4"
                lg="3"
                v-for="item in rssFeeds"
                :key="item.guid"
            >
              <v-card
                  class="mx-auto"
                  elevation="0"
                  @click="openLink(item.link)"
              >
                <v-img :aspect-ratio="16 / 9" :src="item.imageUrl"></v-img>

                <v-card-subtitle class="pa-0 pt-3 caption blue-grey--text">
                  <!--              {{ item.pubDate.substr(0, 16) }}-->
                  {{ item.date.substr(0, 16) }}
                </v-card-subtitle>
                <v-card-title class="pt-2 px-0">
                  <span class="subtitle-1 font-weight-bold leading-normal">{{
                      item.title
                    }}</span>
                </v-card-title>

                <!--            <v-card-text class="text&#45;&#45;primary">-->
                <!--                          <div>{{ item.contentSnippet }}</div>-->
                <!--            </v-card-text>-->
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$leading: (
    "none": 1,
    "tight": 1.25,
    "snug": 1.375,
    "normal": 1.5,
    "relaxed": 1.625,
    "loose": 2,
);

.testi-links {
  color: #00d4ff;
}

.v-application .caption {
  font-size: medium !important;
}

@each $name, $value in $leading {
  .leading-#{$name} {
    line-height: $value !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1185px !important;
  }
}

.v-card__text__height {
  line-height: 1.5em;
  min-height: 7em;
}

.v-card__title {
  word-break: break-word;
}

.content-card {
  word-break: keep-all;

  figure {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.05) linear-gradient(
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 20%,
              rgba(0, 0, 0, 0.85) 100%
      );
      transition-property: all;
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
  }

  &:hover {
    figure {
      &:after {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.landing-header {
  position: relative;
  overflow: hidden;

  &-inner {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 58px;
    line-height: 1;
    @media only screen and (min-width: 600px) {
      font-size: 64px;
      line-height: 1.2;
    }
  }

  p {
    font-size: 21px;
  }

  .animate-text {
    background: linear-gradient(231deg, #083d73, #094879, #00d4ff);
    background-size: 600% 600%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-animation: TextGradientAnimation 5s ease infinite;
    -moz-animation: TextGradientAnimation 5s ease infinite;
    animation: TextGradientAnimation 5s ease infinite;
  }
}

.arrow-vector {
  position: absolute;
  width: 100%;
  max-width: 1185px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  &-inner {
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
    display: grid;
    grid-column: 1/-1;
    grid-row: 1/-1;
    grid-template-areas:
      "topleft topcenter topright"
      "middleleft middlecenter middleright"
      "bottomleft bottomcenter bottomright";
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .arrow-shape {
    position: absolute;
    width: 500px;
    bottom: 0;
    z-index: 1;
    transform: rotate(-15deg);
    right: 5px;

    @media only screen and (min-width: 600px) {
      width: 1000px;
      right: 15px;
    }
  }

  .background-shapes__circle-filled {
    background-color: #0093e9;

    background-image: linear-gradient(
            160deg,
            #0093e9 0%,
            rgba(99, 251, 215, 1) 50%,
            #80d0c7 100%
    );
    mix-blend-mode: multiply;

    border-radius: 100%;
    position: relative;
    height: 590px;
    width: 590px;
    transform: translate3d(-30%, -10%, 0);
    grid-area: bottomcenter;
    @media only screen and (min-width: 600px) {
      height: 1090px;
      width: 1090px;
      transform: translate3d(-25%, -20%, 0);
      grid-area: bottomright;
    }
  }
}

.phone {
  max-width: 230px;
  margin: 30px auto;

  img {
    display: block;
    width: 100%;
  }

  @media only screen and (min-width: 600px) {
    max-width: 300px;
    transform: translateY(15%);
    margin: 0 auto;
  }
}

@-webkit-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@-moz-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

.section {
  &__title {
    display: flex;
    align-items: center;
    margin: 24px 0 20px;

    .icon {
      //width: 48px;
      //height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      margin-right: 6px;

      svg {
        display: block;
        width: 36px;
        height: 36px;
        fill: currentColor;
      }
    }
  }

  &-overview {
    text-align: center;

    p {
      max-width: 700px;
      margin: 0 auto 18px;
      font-size: 16px;
      line-height: 1.6;
      @media (min-width: 768px) {
        font-size: 21px;
        margin: 0 auto 24px;
      }
    }
  }


  &-top {
    margin: 30px 0;
    background-image: linear-gradient(
            0deg,
            #fff 0%,
            rgba(179, 229, 252, 1) 60%,
            #fff 100%
    );
  }
}

.newsletter-form {
  position: relative;
  max-width: 400px;

  .btn-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: calc(0.4rem * 0.75) 12px !important;
    height: auto !important;
  }

  .input {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(0.4rem * 0.75) calc(0.875rem * 0.5);
      margin: calc(0.4rem * 0.75 + 3px) calc(0.5rem * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: #e3f2fd;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 2px solid currentColor;
      padding: calc(0.4rem * 1.5) 0.875rem;
      padding-right: 100px;
      color: currentColor;
      background: #e3f2fd;
      border-radius: 5px;

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.2rem, -65%) scale(0.8);
          color: #1976d2;
        }
      }
    }
  }
}

// substack iframe
.iframe-container {
  &-header {
    position: relative;
    display: inline-flex;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: #e3f2fd;
    }

    &:before {
      left: 0;
      width: 12px;
      height: 100%;
      @media (min-width: 768px) {
        width: 17px;
      }
    }

    &:after {
      right: 0;
      width: 17px;
      height: 100%;
    }

    .top,
    .bottom {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      background: #e3f2fd;
    }

    .top {
      top: 0;
      height: 16px;
    }

    .bottom {
      bottom: 0;
      height: 32px;
    }
  }
}

.sns {
  width: 100%;
  //max-width: 700px;
  margin: 32px auto 0;
  padding: 32px 16px;
  background: rgba(0, 0, 0, .1);
  //border-top: 1px solid rgba(0, 0, 0, .2);
  //border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.btn-sns {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  line-height: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  font-size: 16px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  margin: 5px 0;
  transition: opacity .3s;
}

.btn-sns:hover {
  opacity: .9;
}

.btn-facebook {
  background: rgb(24, 119, 242);
  margin-right: 10px;
}

.btn-twitter {
  background: #1DA1F2;
}
</style>

<script>
// import { default as latest } from "../data/sample-feed";
// import Parser from "rss-parser";
import axios from "axios";

export default {
  name: "Landing",

  mounted() {
    document.title = "The Coffee Break";

    let urlParams = new URLSearchParams(window.location.search);
    this.referral = urlParams.get("referral") || urlParams.get("ref") || "";

    this.retrieveFeed();
  },
  data() {
    return {
      busy: false,
      email: "",
      referral: "",
      errorMessage: "",
      errorBox: false,
      successBox: false,
      successDialog: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      rssFeeds: null,
      topPosts: [
        {
          title:
              "Meta (fka as Facebook) paid USD60 mil to acquire the Meta trademark assets from a US regional bank, Meta Financial Group",
          subtitle:
              "Feb deadline for Sinovac senior citizen recipients to obtain booster shots. Reddit, the front page of the Internet has filed for an IPO. Watch a spacecraft flying through the Sun's atmosphere.",
          date: "Fri, Dec 17 2021",
          imageUrl:
              "https://cdn.substack.com/image/upload/w_728,c_limit/zwwwgdr8m1p6buhjebyx",
          link: "https://thecoffeebreak.co/p/-meta-fka-as-facebook-paid-usd60",
        },
        {
          title:
              'British aeronautical engineer "very confident" to have identified MH370 plane location',
          subtitle:
              "Former PM Muhyiddin optimistic PN will be people's choice for GE15. Didi to delist in Nasdaq, relist in Hong Kong. Thai conglomerate Central Group to acquire Selfridges & Co for GBP4 bil.",
          date: "Mon, Dec 6 2021",
          imageUrl:
              "https://cdn.substack.com/image/youtube/w_728,c_limit/SBskBD4jbtE",
          link: "https://thecoffeebreak.co/p/-british-aeronautical-engineer-very",
        },
        {
          title:
              "LHDN soon can check your bank account(s) without your consent",
          subtitle:
              "ECF platform pitchIN hit RM200 mil milestone raised. Amazon Web Services to invest USD5 bil in Indonesia, creating 24.7k jobs. Japanese gov admitted to overstating country's economic growth figure.",
          date: "Thu, Dec 16 2021",
          imageUrl:
              "https://cdn.substack.com/image/youtube/w_728,c_limit/ywV5JMDHiAY",
          link: "https://thecoffeebreak.co/p/-lhdn-soon-can-check-your-bank-accounts",
        },
        {
          title: "Vegetable prices in Malaysia experiencing hyperinflation\n",
          subtitle:
              "Employees in MY are expected to see a real salary increase of 2.8% in 2022. Average of USD241 bil in nominal value of Tesla options traded daily. Sweden gets its first-ever female prime minister.",
          date: "Thu, Nov 25 2021",
          imageUrl:
              "https://cdn.substack.com/image/fetch/w_336,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F76a0a55c-bb9b-4f87-8d07-46213de3e743_627x680.jpeg",
          link: "https://thecoffeebreak.co/p/-vegetable-prices-in-malaysia-experiencing",
        },
      ],
      testimonials: [
        {
          source: "Twitter",
          content:
              "I spend a LOT of time online and I still miss half the news in this curated newsletter. Go subscribe to The Coffee Break!",
          user: "@surayaror",
          title: "Financial Blogger",
          twitterLink: "https://twitter.com/surayaror",
          avatar:
              "https://pbs.twimg.com/profile_images/1423096625975631874/LG1sVW3N_400x400.jpg",
        },
        {
          source: "Twitter",
          content:
              "Received my 2nd newsletter for @BreakMyCoffee over coffee. Love it, takes out all the noise in the news. Highly recommend you to subscribe to them & stay informed about latest news.",
          user: "@shannonchowz",
          twitterLink: "https://twitter.com/shannonchowz",
          avatar:
              "https://pbs.twimg.com/profile_images/1377946307461926912/xfHLhKI3_400x400.jpg",
        },
        {
          source: "Twitter",
          content: "Their newsletter is a great stuff seriously. Do subscribe!",
          user: "@bitcoinmalaya",
          twitterLink: "https://twitter.com/bitcoinmalaya",
          avatar:
              "https://pbs.twimg.com/profile_images/1469564380119400448/yOOthAoM_400x400.jpg",
        },
      ],
    };
  },
  methods: {
    async submitEmail() {
      this.busy = true;
      if (this.email === "") {
        this.errorBox = true;
        this.errorMessage = "Please key in your email";
        this.busy = false;
        return;
      }
      try {
        await axios.post(
            "https://hukopzhug3.execute-api.ap-southeast-1.amazonaws.com/production/email/submit",
            {
              email: this.email,
              referral: this.referral,
            }
        );
        // this.successBox = true;
        this.successDialog = true;
        // this.email = "";
        // this.referral = "";
        this.busy = false;
      } catch (err) {
        this.busy = false;
        this.errorMessage = "Something is not right, please try again later :(";
        this.errorBox = true;
      }
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    async retrieveFeed() {
      let feedUrl =
          "https://hukopzhug3.execute-api.ap-southeast-1.amazonaws.com/production/retrieve-top-feeds";
      const result = await axios.get(feedUrl);
      this.rssFeeds = result.data.result;
    },
  },
};
</script>
