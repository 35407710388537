<template>
  <div>
    <div class="landing-header blue lighten-5">
      <div class="arrow-vector">
        <div class="arrow-vector-inner">
          <div class="background-shapes__circle-filled"></div>
          <div class="arrow-shape">
            <img src="../assets/arrow.svg" alt=""/>
          </div>
        </div>
      </div>
      <v-container class="py-0 landing-header-inner">
        <v-row>
          <v-col
              cols="11"
              md="7"
              lg="6"
              class="d-flex align-center justify-center flex-column"
          >
            <div class="pt-6 px-4 pa-md-0">
              <h1 class="font-weight-black mb-3">
                <span class="d-inline-block animate-text font-weight-black"
                >The Coffee Break Referral Programme</span
                >
              </h1>
              <p
                  class="font-weight-regular blue-grey--text text--darken-4 headline"
              >
                Terms and Conditions
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="5" lg="6">
            <div class="phone">
              <img src="../assets/phone.png" alt=""/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="section-overview mt-8 mt-md-14 mb-8">
      <v-container style="text-align: left">
        <h4>Terms and Conditions of The Coffee Break’s Referral programme</h4>
        Last updated: 18 Apr 2024

        <br><br>

        <ol>
          <li>
            General Digital Sdn Bhd (“GDSB" or “We”) offers subscribers (“Referrer” or “You”) the opportunity to participate in The Coffee Break’s New Subscriber Beta Referral programme (the “Programme”).
          </li>
          <li>
            Referrers must respect the spirit of the programme by only referring real individuals who meet the requirements of these Terms and Conditions. Referrers cannot refer themselves. For example, a Referrer may not participate in the programme using multiple or fake email addresses or identities.
          </li>
          <li>
            Individuals who receive a referral via a Referrer are "Friends" (or, singly, a “Friend”). An "Eligible" Referrer who is fully compliant with these Terms and Conditions may receive "Reward(s)" for every "Qualified Referral” (all terms in quotes to be understood as defined below).
          </li>
          <li>By participating in the programme, a Referrer represents that he or she has her Friends’ prior consent to provide their contact information.
          </li>
          <li>
            The program will run until 19 May 2024 and renewal is subject to the discretion of GDSB.
          </li>
          <li>
            A “Qualified Referral” means that all the following conditions are met:
            <ul>
              <li>The Qualified Referral confirmed his or her subscription to The Coffee Break Newsletter.</li>
              <li>The Qualified Referral has not been used to subscribe to The Coffee Break newsletter before. If the Qualified Referral’s email has previously subscribed to The Coffee Break but subsequently unsubscribed, the email will not qualify as part of the programme.
              </li>
              <li>
                It is our discretion to remove any qualified referral whom we deem not to be a genuine subscriber even if it meets the above criteria.
              </li>
            </ul>
          </li>
          <li>
            By successfully referring THREE (3) Qualified Referral, a Referrer will receive RM10 ZUS Coffee Voucher (“Reward”), up to a maximum of 9 Qualified Referral for a total reward of 3 RM10 ZUS Coffee Voucher.
          </li>
          <li>The reward may be redeemed in various forms at GDSB’s sole discretion.</li>
          <li>Rewards are not transferable and may not be auctioned, traded, bartered or sold. Upon termination of the referral program or any portion thereof for any reason, any unredeemed Rewards that have not yet been delivered to Referrer are forfeited.</li>
          <li>Exploiting the programme or assisting others to exploit the programme is strictly prohibited, and will result in disqualification from the programme.
          </li>
          <li>
            We reserve the right to amend or terminate the programme at any time, for any reason. The programme is administered by GDSB. Referrers are bound by these Terms and Conditions by participating in the programme. By participating in the programme, Referrers agree to use the programme in the manner specified in these Terms and Conditions. If you do not agree to these Terms and Conditions in their entirety, you are not authorized to register as a Referrer or to participate in the programme in any other manner. Referrers may not participate in the programme where doing so would be prohibited by any applicable law or regulations. We reserve the right to modify or amend at any time these Terms and Conditions and/or the methods through which rewards are earned. We reserve the right to disqualify any Referrers from participation in the programme at any time at our sole discretion, including without limitation if he/she does not comply with any of these Terms and Conditions or otherwise fails to comply with any applicable laws.
          </li>
          <li>
            No part of the program is directed to persons under the age of 18. IF YOU ARE UNDER 18 YEARS OF AGE, YOU MAY NOT USE, ACCESS OR PARTICIPATE IN THE PROGRAM AT ANY TIME OR IN ANY MANNER.
          </li>
          <li>
            There is no affiliation between GDSB and Zuspresso (M) Sdn Bhd.
          </li>
          <li>
            Any dispute in relation to quality, merchantability and/or warranty of the  Reward (whichever applicable) must be settled directly by the Referral with the dealer/supplier without recourse to GDSB. GSDB is not responsible for any breach of quality or warranty of the Reward or any terms and conditions thereof.
          </li>
        </ol>

        <br>

        <h4>LIMITATION ON LIABILITY AND DISCLAIMER OF WARRANTIES</h4>
        <div>
          By participating in this programme, you agree that GDSB and its agents, representatives, affiliates, officers, directors and employees will have no liability whatsoever for any injuries, losses, claims, damages or any special, exemplary, punitive, indirect, incidental or consequential damages of any kind, whether based in contract, tort, strict liability or otherwise, resulting from any use of the programme, any failure or delay by GDSB in connection with the programme, or the performance or non-performance of the programme by GDSB, even if GDSB has been advised of the possibility of damages. This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation, computer virus, communications line failure, theft or destruction, or unauthorised access to, alteration of, or use of your information.
        </div>
        <br>
        <div>
          GDSB makes no warranty of any kind regarding the programme, which is provided on an “as is” and “as available” basis.  GDSB expressly disclaims all warranties, including implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, and those arising by statute or otherwise in law or from a course of dealing or usage of trade.  GDSB is not responsible or liable for any warranty, representation, or guarantee, express or implied, in fact or in law, relative to the programme, including without limitation that the programme will be error-free, or as to the accuracy, completeness and timeliness of any content or information distributed with respect to the programme.  SOME STATES AND COUNTRIES DO NOT ALLOW THE LIMITATION OF LIABILITY AND DISCLAIMER OF IMPLIED WARRANTIES, SO THE DISCLAIMERS AND LIMITATIONS ABOVE, IN WHOLE OR IN PART, MAY NOT APPLY TO YOU.
        </div>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$leading: (
    "none": 1,
    "tight": 1.25,
    "snug": 1.375,
    "normal": 1.5,
    "relaxed": 1.625,
    "loose": 2,
);

.testi-links {
  color: #00d4ff;
}

.v-application .caption {
  font-size: medium !important;
}

@each $name, $value in $leading {
  .leading-#{$name} {
    line-height: $value !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1185px !important;
  }
}

.v-card__text__height {
  line-height: 1.5em;
  min-height: 7em;
}

.v-card__title {
  word-break: break-word;
}

.content-card {
  word-break: keep-all;

  figure {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.05) linear-gradient(
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 20%,
              rgba(0, 0, 0, 0.85) 100%
      );
      transition-property: all;
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
  }

  &:hover {
    figure {
      &:after {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.landing-header {
  position: relative;
  overflow: hidden;

  &-inner {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 58px;
    line-height: 1;
    @media only screen and (min-width: 600px) {
      font-size: 64px;
      line-height: 1.2;
    }
  }

  p {
    font-size: 21px;
  }

  .animate-text {
    background: linear-gradient(231deg, #083d73, #094879, #00d4ff);
    background-size: 600% 600%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-animation: TextGradientAnimation 5s ease infinite;
    -moz-animation: TextGradientAnimation 5s ease infinite;
    animation: TextGradientAnimation 5s ease infinite;
  }
}

.arrow-vector {
  position: absolute;
  width: 100%;
  max-width: 1185px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  &-inner {
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
    display: grid;
    grid-column: 1/-1;
    grid-row: 1/-1;
    grid-template-areas:
      "topleft topcenter topright"
      "middleleft middlecenter middleright"
      "bottomleft bottomcenter bottomright";
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .arrow-shape {
    position: absolute;
    width: 500px;
    bottom: 0;
    z-index: 1;
    transform: rotate(-15deg);
    right: 5px;

    @media only screen and (min-width: 600px) {
      width: 1000px;
      right: 15px;
    }
  }

  .background-shapes__circle-filled {
    background-color: #0093e9;

    background-image: linear-gradient(
            160deg,
            #0093e9 0%,
            rgba(99, 251, 215, 1) 50%,
            #80d0c7 100%
    );
    mix-blend-mode: multiply;

    border-radius: 100%;
    position: relative;
    height: 590px;
    width: 590px;
    transform: translate3d(-30%, -10%, 0);
    grid-area: bottomcenter;
    @media only screen and (min-width: 600px) {
      height: 1090px;
      width: 1090px;
      transform: translate3d(-25%, -20%, 0);
      grid-area: bottomright;
    }
  }
}

.phone {
  max-width: 230px;
  margin: 30px auto;

  img {
    display: block;
    width: 100%;
  }

  @media only screen and (min-width: 600px) {
    max-width: 300px;
    transform: translateY(15%);
    margin: 0 auto;
  }
}

@-webkit-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@-moz-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

.section {
  &__title {
    display: flex;
    align-items: center;
    margin: 24px 0 20px;

    .icon {
      //width: 48px;
      //height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      margin-right: 6px;

      svg {
        display: block;
        width: 36px;
        height: 36px;
        fill: currentColor;
      }
    }
  }

  &-overview {
    text-align: center;

    p {
      max-width: 700px;
      margin: 0 auto 18px;
      font-size: 16px;
      line-height: 1.6;
      @media (min-width: 768px) {
        font-size: 21px;
        margin: 0 auto 24px;
      }
    }
  }


  &-top {
    margin: 30px 0;
    background-image: linear-gradient(
            0deg,
            #fff 0%,
            rgba(179, 229, 252, 1) 60%,
            #fff 100%
    );
  }
}

.newsletter-form {
  position: relative;
  max-width: 400px;

  .btn-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: calc(0.4rem * 0.75) 12px !important;
    height: auto !important;
  }

  .input {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(0.4rem * 0.75) calc(0.875rem * 0.5);
      margin: calc(0.4rem * 0.75 + 3px) calc(0.5rem * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: #e3f2fd;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 2px solid currentColor;
      padding: calc(0.4rem * 1.5) 0.875rem;
      padding-right: 100px;
      color: currentColor;
      background: #e3f2fd;
      border-radius: 5px;

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.2rem, -65%) scale(0.8);
          color: #1976d2;
        }
      }
    }
  }
}

// substack iframe
.iframe-container {
  &-header {
    position: relative;
    display: inline-flex;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: #e3f2fd;
    }

    &:before {
      left: 0;
      width: 12px;
      height: 100%;
      @media (min-width: 768px) {
        width: 17px;
      }
    }

    &:after {
      right: 0;
      width: 17px;
      height: 100%;
    }

    .top,
    .bottom {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      background: #e3f2fd;
    }

    .top {
      top: 0;
      height: 16px;
    }

    .bottom {
      bottom: 0;
      height: 32px;
    }
  }
}

.sns {
  width: 100%;
  //max-width: 700px;
  margin: 32px auto 0;
  padding: 32px 16px;
  background: rgba(0, 0, 0, .1);
  //border-top: 1px solid rgba(0, 0, 0, .2);
  //border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.btn-sns {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  line-height: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  font-size: 16px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  margin: 5px 0;
  transition: opacity .3s;
}

.btn-sns:hover {
  opacity: .9;
}

.btn-facebook {
  background: rgb(24, 119, 242);
  margin-right: 10px;
}

.btn-twitter {
  background: #1DA1F2;
}
</style>

<script>
export default {
  name: "TermsConditionsReferral",

  mounted() {
    document.title = "The Coffee Break";
  },
  data() {
    return {
      busy: false,
      email: "",
      errorMessage: "",
      errorBox: false,
      successBox: false,
      successDialog: false,
      rssFeeds: null,
    };
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
