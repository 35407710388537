<template>
  <div>
    <div class="landing-header blue lighten-5">
      <div class="arrow-vector">
        <div class="arrow-vector-inner">
          <div class="background-shapes__circle-filled"></div>
<!--          <div class="arrow-shape">-->
<!--            <img src="../assets/arrow.svg" alt=""/>-->
<!--          </div>-->
        </div>
      </div>
      <v-container class="py-0 landing-header-inner">
        <v-row>
          <v-col
              cols="11"
              md="7"
              lg="6"
              class="d-flex align-center justify-center flex-column"
          >
            <div class="pt-6 px-4 pa-md-0">
              <h1 class="font-weight-black mb-3">
                <span class="d-inline-block animate-text font-weight-black">Career</span>
              </h1>
              <p
                  class="font-weight-regular blue-grey--text text--darken-4 headline"
              >
                We are looking for part-time writers to join our editorial team!
              </p>

              <div>
                  Preferably interns, students studying journalism, media or in
                  relevant field. That said, open to explore any background.
                  <br><br>
                  <b>Scope of work</b>:
                  <br>Read and write summarised news for a section(s) in a daily issue. This task will take an average of 1-2 hours.
                  <br><br>
                  <b>Engagement frequency</b>: <br>
                  At least 1 issue per week (we publish 5 daily issues a week). Writing is usually done the evening/night
                  before the following day's issue. Frequency will increase over
                  time depends on quality of work and reliability.
<br>
                  <br>
                <b>Location</b>: If you work from your bed, then your bed is the location (aka remote)
                  <br><br>
                  <b>Rate (RM)</b>: RM25 per issue (Negotiable, subject to experience) <br>

                  <br>
                <b>Requirements</b>:
                  <br>1. Proficient in English
                  <br>2. Follow, read and take interest in current affairs, business and financial news
                  <br>3. Flair in writing
                  <br>4. Goes without saying - have integrity and responsible

                  <br><br>Plus point if you like Bitcoin (and crypto)
                  <br>Plus point if you are a funny person.

                  <br><br>Fill the form here if you are interested to apply.
                  <a href="https://forms.gle/Tfw3ut7M7DtA3NRP8">https://forms.gle/Tfw3ut7M7DtA3NRP8</a>
                  <br/>
              </div>


              <div class="my-2">
                <a
                    href="https://www.facebook.com/breakmycoffee"
                    target="_blank"
                    class="btn-sns btn-facebook"
                >
                  <v-icon class="me-2" color="white"> mdi-facebook</v-icon>
                  <span>The Coffee Break</span>
                </a>
                <a
                    href="https://twitter.com/BreakMyCoffee"
                    target="_blank"
                    class="btn-sns btn-twitter"
                >
                  <v-icon class="me-2" color="white">mdi-twitter</v-icon>
                  <span>@breakmycoffee</span>
                </a>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="5" lg="6">
            <div class="phone">
              <img src="../assets/phone.png" alt=""/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$leading: (
    "none": 1,
    "tight": 1.25,
    "snug": 1.375,
    "normal": 1.5,
    "relaxed": 1.625,
    "loose": 2,
);

.testi-links {
  color: #00d4ff;
}

.v-application .caption {
  font-size: medium !important;
}

@each $name, $value in $leading {
  .leading-#{$name} {
    line-height: $value !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1185px !important;
  }
}

.v-card__text__height {
  line-height: 1.5em;
  min-height: 7em;
}

.v-card__title {
  word-break: break-word;
}

.content-card {
  word-break: keep-all;

  figure {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.05) linear-gradient(
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 20%,
              rgba(0, 0, 0, 0.85) 100%
      );
      transition-property: all;
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
  }

  &:hover {
    figure {
      &:after {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.landing-header {
  position: relative;
  overflow: hidden;

  &-inner {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 58px;
    line-height: 1;
    @media only screen and (min-width: 600px) {
      font-size: 64px;
      line-height: 1.2;
    }
  }

  p {
    font-size: 21px;
  }

  .animate-text {
    background: linear-gradient(231deg, #083d73, #094879, #00d4ff);
    background-size: 600% 600%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-animation: TextGradientAnimation 5s ease infinite;
    -moz-animation: TextGradientAnimation 5s ease infinite;
    animation: TextGradientAnimation 5s ease infinite;
  }
}

.arrow-vector {
  position: absolute;
  width: 100%;
  max-width: 1185px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  &-inner {
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
    display: grid;
    grid-column: 1/-1;
    grid-row: 1/-1;
    grid-template-areas:
      "topleft topcenter topright"
      "middleleft middlecenter middleright"
      "bottomleft bottomcenter bottomright";
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .arrow-shape {
    position: absolute;
    width: 500px;
    bottom: 0;
    z-index: 1;
    transform: rotate(-15deg);
    right: 5px;

    @media only screen and (min-width: 600px) {
      width: 1000px;
      right: 15px;
    }
  }

  .background-shapes__circle-filled {
    background-color: #0093e9;

    background-image: linear-gradient(
            160deg,
            #0093e9 0%,
            rgba(99, 251, 215, 1) 50%,
            #80d0c7 100%
    );
    mix-blend-mode: multiply;

    border-radius: 100%;
    position: relative;
    height: 590px;
    width: 590px;
    transform: translate3d(-30%, -10%, 0);
    grid-area: bottomcenter;
    @media only screen and (min-width: 600px) {
      height: 1090px;
      width: 1090px;
      transform: translate3d(-25%, -20%, 0);
      grid-area: bottomright;
    }
  }
}

.phone {
  max-width: 230px;
  margin: 30px auto;

  img {
    display: block;
    width: 100%;
  }

  @media only screen and (min-width: 600px) {
    max-width: 300px;
    transform: translateY(15%);
    margin: 0 auto;
  }
}

@-webkit-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@-moz-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

.section {
  &__title {
    display: flex;
    align-items: center;
    margin: 24px 0 20px;

    .icon {
      //width: 48px;
      //height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      margin-right: 6px;

      svg {
        display: block;
        width: 36px;
        height: 36px;
        fill: currentColor;
      }
    }
  }

  &-overview {
    text-align: center;

    p {
      max-width: 700px;
      margin: 0 auto 18px;
      font-size: 16px;
      line-height: 1.6;
      @media (min-width: 768px) {
        font-size: 21px;
        margin: 0 auto 24px;
      }
    }
  }

  &-top {
    margin: 30px 0;
    background-image: linear-gradient(
            0deg,
            #fff 0%,
            rgba(179, 229, 252, 1) 60%,
            #fff 100%
    );
  }
}

.newsletter-form {
  position: relative;
  max-width: 400px;

  .btn-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: calc(0.4rem * 0.75) 12px !important;
    height: auto !important;
  }

  .input {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(0.4rem * 0.75) calc(0.875rem * 0.5);
      margin: calc(0.4rem * 0.75 + 3px) calc(0.5rem * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: #e3f2fd;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 2px solid currentColor;
      padding: calc(0.4rem * 1.5) 0.875rem;
      padding-right: 100px;
      color: currentColor;
      background: #e3f2fd;
      border-radius: 5px;

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.2rem, -65%) scale(0.8);
          color: #1976d2;
        }
      }
    }
  }
}

// substack iframe
.iframe-container {
  &-header {
    position: relative;
    display: inline-flex;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: #e3f2fd;
    }

    &:before {
      left: 0;
      width: 12px;
      height: 100%;
      @media (min-width: 768px) {
        width: 17px;
      }
    }

    &:after {
      right: 0;
      width: 17px;
      height: 100%;
    }

    .top,
    .bottom {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      background: #e3f2fd;
    }

    .top {
      top: 0;
      height: 16px;
    }

    .bottom {
      bottom: 0;
      height: 32px;
    }
  }
}

.sns {
  width: 100%;
  //max-width: 700px;
  margin: 32px auto 0;
  padding: 32px 16px;
  background: rgba(0, 0, 0, 0.1);
  //border-top: 1px solid rgba(0, 0, 0, .2);
  //border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.btn-sns {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  line-height: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  font-size: 16px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  margin: 5px 0;
  transition: opacity 0.3s;
}

.btn-sns:hover {
  opacity: 0.9;
}

.btn-facebook {
  background: rgb(24, 119, 242);
  margin-right: 10px;
}

.btn-twitter {
  background: #1da1f2;
}
</style>

<script>
// import { default as latest } from "../data/sample-feed";
// import Parser from "rss-parser";
import axios from "axios";

export default {
  name: "Career",

  mounted() {
    document.title = "Careers | The Coffee Break";
  },
  data() {
    return {
      busy: false,
    };
  },
  methods: {
    async submitEmail() {
      this.busy = true;
      if (this.email === "") {
        this.errorBox = true;
        this.errorMessage = "Please key in your email";
        this.busy = false;
        return;
      }
      try {
        await axios.post(
            "https://hukopzhug3.execute-api.ap-southeast-1.amazonaws.com/production/email/submit",
            {
              email: this.email,
              referral: this.referral,
            }
        );
        // this.successBox = true;
        this.successDialog = true;
        // this.email = "";
        // this.referral = "";
        this.busy = false;
      } catch (err) {
        this.busy = false;
        this.errorMessage = "Something is not right, please try again later :(";
        this.errorBox = true;
      }
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    async retrieveFeed() {
      let feedUrl =
          // "https://qglljpzd97.execute-api.ap-southeast-1.amazonaws.com/dev/retrieve-top-feeds";
      "https://hukopzhug3.execute-api.ap-southeast-1.amazonaws.com/production/retrieve-top-feeds";
      const result = await axios.get(feedUrl);
      this.rssFeeds = result.data.result;
    },
  },
};
</script>
