<template>
  <div>
    <div class="landing-header blue lighten-5">
      <div class="d-none d-lg-block">
        <div class="arrow-vector">
          <div class="arrow-vector-inner">
            <div class="background-shapes__circle-filled"></div>
            <div class="arrow-shape">
              <img src="../assets/arrow.svg" alt=""/>
            </div>
          </div>
        </div>
      </div>
      <v-container class="py-0 landing-header-inner">
        <v-row>
          <v-col
              cols="11"
              md="7"
              lg="6"
              class="d-flex align-center justify-center flex-column"
          >
            <div class="pt-6 pl-4 pa-md-0">
              <h1 class="font-weight-black mb-3">
                Stay informed, be smarter in
                <span class="d-inline-block animate-text font-weight-black"
                >3 minutes</span
                >
              </h1>
              <p
                  class="font-weight-regular blue-grey--text text--darken-4 headline"
              >
                The daily newsletter of 20,000+ Malaysians. We make it easy and entertaining to read news.
              </p>

              <v-row>
                <v-col cols="12" sm="9" md="9" lg="9">
                  <div>
                    <v-text-field
                        label="Email"
                        v-model="email"
                        background-color="white"
                        :rules="[rules.required, rules.email]"
                        outlined
                        dense
                    ></v-text-field>
                    <v-text-field
                        label="Referral Code (Optional)"
                        v-model="referral"
                        background-color="white"
                        outlined
                        dense
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <div class="flex align-center">
                <v-row>
                  <v-col cols="12" md="5">
                    <v-btn
                        class="v-btn--block"
                        style="color: white"
                        elevation="2"
                        color="#007cb7"
                        large
                        :loading="busy"
                        :disabled="busy"
                        @click="submitEmail"
                    >
                      <v-icon left> mdi-send</v-icon>
                      Subscribe For Free
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn
                        class="v-btn--block btn-support-us"
                        elevation="2"
                        large
                        @click="gotoSupport"
                    >
                      <v-icon left> mdi-gift-outline</v-icon>
                      Support Us
                    </v-btn>
                  </v-col>
                </v-row>
                <!--                <a-->
                <!--                  class="v-btn v-size&#45;&#45;large pa-0 ms-2 btn-buyMeCoffee"-->
                <!--                  href="https://www.buymeacoffee.com/BreakMyCoffee"-->
                <!--                  target="_blank"-->
                <!--                >-->
                <!--                  <img-->
                <!--                    src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"-->
                <!--                    alt="Buy Me A Coffee"-->
                <!--                    style="height: 44px"-->
                <!--                  />-->
                <!--                </a>-->
              </div>
              <div>
                <v-dialog
                    v-model="successDialog"
                    transition="dialog-bottom-transition"
                    max-width="600"
                >
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark
                      >You're now on our mailing list!
                      </v-toolbar>
                      <v-card-text>
                        <div class="pa-4">
                          <h3>Wise choice for subscribing!</h3>
                          <br/>
<!--                          <div>-->
<!--                            Open your inbox to <b>CONFIRM</b> your subscription from an email we sent you.-->
<!--                          </div>-->
                          <div>
                            Check your inbox for our welcome email!
                          </div>
<!--                          <br/>-->
<!--                          <div>-->
<!--                            Then enjoy The Coffee Break in your inbox.-->
<!--                          </div>-->
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <v-snackbar
                    :timeout="3000"
                    :value="true"
                    v-model="errorBox"
                    tile
                    color="red accent-2"
                >
                  {{ errorMessage }}
                </v-snackbar>
                <v-snackbar
                    :timeout="3000"
                    :value="true"
                    v-model="successBox"
                    shaped
                    color="blue accent-4"
                >
                  Thanks for <strong>subscribing</strong>!
                </v-snackbar>
              </div>

              <!--              <div-->
              <!--                class="iframe-container-header"-->
              <!--                style="transform: translateX(-15px)"-->
              <!--              >-->
              <!--                <div class="top"></div>-->
              <!--                <button class="d-none" @click="handleClick">test</button>-->
              <!--                <iframe-->
              <!--                  src="https://thecoffeebreak.substack.com/embed"-->
              <!--                  width="340"-->
              <!--                  height="100"-->
              <!--                  frameborder="0"-->
              <!--                  scrolling="no"-->
              <!--                ></iframe>-->
              <!--                <div class="bottom"></div>-->
              <!--              </div>-->
            </div>
          </v-col>
          <v-col cols="12" md="5" lg="6">
            <div class="phone d-none d-md-flex">
              <img src="../assets/phone.png" alt=""/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="section-overview mt-md-14 pb-10 d-md-none" style="background-color:rgb(21, 32, 43)">
      <v-col cols="12" class="py-0 text-center">
        <h2 class="mx-auto pt-3 pb-3 mb-3 text-center">
          <span class="icon">
            <svg
                class="blue-grey--text text--lighten-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
              <path
                  d="M21,10H3a1,1,0,0,0-1,1,10,10,0,0,0,5,8.66V21a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V19.66A10,10,0,0,0,22,11,1,1,0,0,0,21,10Zm-5.45,8.16a1,1,0,0,0-.55.9V20H9v-.94a1,1,0,0,0-.55-.9A8,8,0,0,1,4.06,12H19.94A8,8,0,0,1,15.55,18.16Z"
                  fill="white"
              ></path>
              <path
                  d="M9,9V7.93A4.53,4.53,0,0,0,7.72,4.78,2.49,2.49,0,0,1,7,3V2H5V3A4.53,4.53,0,0,0,6.28,6.17,2.49,2.49,0,0,1,7,7.93V9Z"
                  fill="currentColor"
              ></path>
              <path
                  d="M13,9V7.93a4.53,4.53,0,0,0-1.28-3.15A2.49,2.49,0,0,1,11,3V2H9V3a4.53,4.53,0,0,0,1.28,3.15A2.49,2.49,0,0,1,11,7.93V9Z"
                  fill="currentColor"
              ></path>
              <path
                  d="M17,9V7.93a4.53,4.53,0,0,0-1.28-3.15A2.49,2.49,0,0,1,15,3V2H13V3a4.53,4.53,0,0,0,1.28,3.15A2.49,2.49,0,0,1,15,7.93V9Z"
                  fill="currentCOlor"
              ></path>
            </svg>
          </span>
          <span class="font-weight-black mt-3 red--text"> Latest </span>
        </h2>
      </v-col>
      <v-row class="d-md-none">
        <template v-if="!firstTwoRssFeed">
          <v-col cols="12" class="px-5">
            <v-skeleton-loader
                :boilerplate="true"
                elevation="2"
                type="table-heading, list-item-two-line"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
      <template v-if="firstTwoRssFeed">
        <v-col
            cols="12"
            v-for="item in firstTwoRssFeed"
            :key="item.guid"
        >
          <v-card
              @click="openLink(item.link)"
          >
            <v-card-title class="pt-2 px-3">
              <span class="subtitle-1 font-weight-bold leading-normal">{{item.title }}</span>
            </v-card-title>
            <v-card-subtitle>
              <div v-for="content in item.content" :key="`${content.substr(0,10)}`">
                <img width="15" src="../assets/pin.svg" alt=""/>{{content}}
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </template>
    </div>
    <hr />
    <div class="section-overview mt-8 mt-md-14">
      <v-container class="goal-container">
        <p class="blue-grey--text text--darken-4 text-h5 d-md-none">
          Our goal is simple - to make our readers more informed.
          <span style="opacity: 0.6">
            We filter the news to take out the noise and at the same time, our
            readers can know and learn more.
          </span>
        </p>
        <p class="text-h5 d-md-none" style="opacity: 0.6">
          We cover interesting facts, local and global news all in our daily
          newsletter.
        </p>
        <p class="blue-grey--text text--darken-4 d-none d-md-block">
          Our goal is simple - to make our readers more informed.
          <span style="opacity: 0.6">
            We filter the news to take out the noise and at the same time, our
            readers can know and learn more.
          </span>
        </p>
        <p class="d-none d-md-block" style="opacity: 0.6">
          We cover interesting facts, local and global news all in our daily
          newsletter.
        </p>
      </v-container>
    </div>
    <div class="section-twitter">
      <v-container>
        <v-row class="mt-2 mt-md-8">
          <v-col
              cols="12"
              sm="4"
              v-for="testi in testimonials"
              :key="testi.user"
          >
            <v-card
                class="rounded-lg mx-auto fill-height d-flex flex-column"
                color="#15202B"
                dark
                max-width="400"
            >
              <v-card-title>
                <v-icon left color="#1d9bf0"> mdi-twitter</v-icon>
              </v-card-title>

              <v-card-text class="title v-card__text__height flex-grow-1">
                <span class="white--text">{{ testi.content }}</span>
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-avatar color="grey darken-3">
                    <v-img
                        class="elevation-6"
                        alt=""
                        :src="testi.avatar"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <a
                        :href="testi.twitterLink"
                        class="testi-links"
                        target="_blank"
                    >
                      <v-list-item-title class="caption"
                      >{{ testi.user }}
                      </v-list-item-title>
                    </a>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--    <div class="section-top">-->
    <!--      <v-container>-->
    <!--        <v-row class="px-3">-->
    <!--          <v-col cols="12" class="pa-0">-->
    <!--            <h1 class="section__title mx-auto">-->
    <!--              <div class="icon">-->
    <!--                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">-->
    <!--                  <path-->
    <!--                      d="M16.5,8c0,1.5-0.5,3.5-2.9,4.3c0.7-1.7,0.8-3.4,0.3-5c-0.7-2.1-3-3.7-4.6-4.6C8.9,2.4,8.2,2.8,8.3,3.4c0,1.1-0.3,2.7-2,4.4  C4.1,10,3,12.3,3,14.5C3,17.4,5,21,9,21c-4-4-1-7.5-1-7.5c0.8,5.9,5,7.5,7,7.5c1.7,0,5-1.2,5-6.4c0-3.1-1.3-5.5-2.4-6.9  C17.3,7.2,16.6,7.5,16.5,8"-->
    <!--                      fill="currentColor"></path>-->
    <!--                </svg>-->
    <!--              </div>-->
    <!--              <span class="font-weight-black light-blue&#45;&#45;text text&#45;&#45;darken-3">Top</span>-->
    <!--            </h1>-->
    <!--          </v-col>-->
    <!--          <template>-->
    <!--            <v-col-->
    <!--                cols="12"-->
    <!--                md="6"-->
    <!--                lg="3"-->
    <!--                v-for="item in topPosts"-->
    <!--                :key="item.title"-->
    <!--                class="pa-0"-->
    <!--            >-->
    <!--              <v-card class="mx-auto content-card pr-1 pb-1" elevation="0" @click="openLink(item.link)">-->
    <!--                <figure>-->
    <!--                  <v-img-->
    <!--                      class="d-block d-md-none"-->
    <!--                      :aspect-ratio="16/9"-->
    <!--                      :src="item.imageUrl"-->
    <!--                  >-->
    <!--                  </v-img>-->
    <!--                  <v-img-->
    <!--                      class="d-none d-md-block"-->
    <!--                      :aspect-ratio="9/16"-->
    <!--                      :src="item.imageUrl"-->
    <!--                  >-->
    <!--                  </v-img>-->
    <!--                </figure>-->
    <!--                <div class="content">-->
    <!--                  <v-card-subtitle class="ma-0 pa-0 caption white&#45;&#45;text">-->
    <!--                    {{ item.date }}-->
    <!--                  </v-card-subtitle>-->
    <!--                  <v-card-title class="pb-0 pl-0 pr-2 pt-2">-->
    <!--                    <span class="d-block headline font-weight-bold leading-snug white&#45;&#45;text">{{ item.title }}</span>-->
    <!--                  </v-card-title>-->
    <!--                </div>-->
    <!--              </v-card>-->
    <!--            </v-col>-->
    <!--          </template>-->
    <!--        </v-row>-->
    <!--      </v-container>-->
    <!--    </div>-->
    <div class="section-latest">
      <v-container>
        <v-row>
          <v-col cols="12" class="py-0">
            <h1 class="section__title mx-auto mb-3">
              <div class="icon">
                <svg
                    class="blue-grey--text text--lighten-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M21,10H3a1,1,0,0,0-1,1,10,10,0,0,0,5,8.66V21a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V19.66A10,10,0,0,0,22,11,1,1,0,0,0,21,10Zm-5.45,8.16a1,1,0,0,0-.55.9V20H9v-.94a1,1,0,0,0-.55-.9A8,8,0,0,1,4.06,12H19.94A8,8,0,0,1,15.55,18.16Z"
                      fill="black"
                  ></path>
                  <path
                      d="M9,9V7.93A4.53,4.53,0,0,0,7.72,4.78,2.49,2.49,0,0,1,7,3V2H5V3A4.53,4.53,0,0,0,6.28,6.17,2.49,2.49,0,0,1,7,7.93V9Z"
                      fill="currentColor"
                  ></path>
                  <path
                      d="M13,9V7.93a4.53,4.53,0,0,0-1.28-3.15A2.49,2.49,0,0,1,11,3V2H9V3a4.53,4.53,0,0,0,1.28,3.15A2.49,2.49,0,0,1,11,7.93V9Z"
                      fill="currentColor"
                  ></path>
                  <path
                      d="M17,9V7.93a4.53,4.53,0,0,0-1.28-3.15A2.49,2.49,0,0,1,15,3V2H13V3a4.53,4.53,0,0,0,1.28,3.15A2.49,2.49,0,0,1,15,7.93V9Z"
                      fill="currentCOlor"
                  ></path>
                </svg>
              </div>
              <span class="font-weight-black red--text"> Latest </span>
            </h1>
          </v-col>
          <template v-if="!rssFeeds">
            <v-col cols="6" md="4" lg="3">
              <v-skeleton-loader
                  :boilerplate="true"
                  elevation="2"
                  type="card-avatar, article, actions"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="6" md="4" lg="3">
              <v-skeleton-loader
                  :boilerplate="true"
                  elevation="2"
                  type="card-avatar, article, actions"
              ></v-skeleton-loader>
            </v-col>
          </template>
          <template v-if="rssFeeds">
            <v-col
                cols="6"
                md="4"
                lg="3"
                v-for="item in rssFeeds"
                :key="item.guid"
            >
              <v-card
                  class="mx-auto"
                  elevation="0"
                  @click="openLink(item.link)"
              >
                <v-img :aspect-ratio="16 / 9" :src="item.imageUrl"></v-img>

                <v-card-subtitle class="pa-0 pt-3 caption blue-grey--text">
                  <!--              {{ item.pubDate.substr(0, 16) }}-->
                  {{ item.date.substr(0, 16) }}
                </v-card-subtitle>
                <v-card-title class="pt-2 px-0">
                  <span class="subtitle-1 font-weight-bold leading-normal">{{
                      item.title
                    }}</span>
                </v-card-title>

                <!--            <v-card-text class="text&#45;&#45;primary">-->
                <!--                          <div>{{ item.contentSnippet }}</div>-->
                <!--            </v-card-text>-->
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-support-us {
  background-color: #7cb701 !important;
  color: white !important;
}
.btn--wiggle {
  animation: 1s wiggle ease infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(-3deg);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
  }
  20% {
    transform: rotate(1deg);
  }
  40% {
    transform: rotate(-3deg);
  }
  60% {
    transform: rotate(1deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
  }
}

$leading: (
    "none": 1,
    "tight": 1.25,
    "snug": 1.375,
    "normal": 1.5,
    "relaxed": 1.625,
    "loose": 2,
);

.testi-links {
  color: #00d4ff;
}

.v-application .caption {
  font-size: medium !important;
}

@each $name, $value in $leading {
  .leading-#{$name} {
    line-height: $value !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1185px !important;
  }
}

.v-card__text__height {
  line-height: 1.5em;
  min-height: 7em;
}

.v-card__title {
  word-break: break-word;
}

.content-card {
  word-break: keep-all;

  figure {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.05) linear-gradient(
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 20%,
              rgba(0, 0, 0, 0.85) 100%
      );
      transition-property: all;
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
  }

  &:hover {
    figure {
      &:after {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

//.goal-container {
//  padding: 18px;
//  @media only screen and (max-width: 500px) {
//    font-size: 22px !important;
//    line-height: 1.2;
//  }
//}

.landing-header {
  position: relative;
  overflow: hidden;

  &-inner {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 32px;
    line-height: 1;
    @media only screen and (min-width: 600px) {
      font-size: 54px;
      line-height: 1.2;
    }
  }

  .headline {
    font-size: 1.2rem !important;
    line-height: 1;
    @media only screen and (min-width: 600px) {
      line-height: 1.2;
    }
  }

  p {
    font-size: 21px;
  }

  .animate-text {
    background: linear-gradient(231deg, #083d73, #094879, #00d4ff);
    background-size: 600% 600%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-animation: TextGradientAnimation 5s ease infinite;
    -moz-animation: TextGradientAnimation 5s ease infinite;
    animation: TextGradientAnimation 5s ease infinite;
  }
}

.arrow-vector {
  position: absolute;
  width: 100%;
  max-width: 1185px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  &-inner {
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
    display: grid;
    grid-column: 1/-1;
    grid-row: 1/-1;
    grid-template-areas:
      "topleft topcenter topright"
      "middleleft middlecenter middleright"
      "bottomleft bottomcenter bottomright";
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .arrow-shape {
    position: absolute;
    width: 500px;
    bottom: 0;
    z-index: 1;
    transform: rotate(-15deg);
    right: 5px;

    @media only screen and (min-width: 600px) {
      width: 1000px;
      right: 15px;
    }
  }

  .background-shapes__circle-filled {
    background-color: #0093e9;

    background-image: linear-gradient(
            160deg,
            #0093e9 0%,
            rgba(99, 251, 215, 1) 50%,
            #80d0c7 100%
    );
    mix-blend-mode: multiply;

    border-radius: 100%;
    position: relative;
    height: 590px;
    width: 590px;
    transform: translate3d(-30%, -10%, 0);
    grid-area: bottomcenter;
    @media only screen and (min-width: 600px) {
      height: 1090px;
      width: 1090px;
      transform: translate3d(-25%, -20%, 0);
      grid-area: bottomright;
    }
  }
}

.phone {
  max-width: 230px;
  margin: 30px auto;

  img {
    display: block;
    width: 100%;
  }

  @media only screen and (min-width: 600px) {
    max-width: 300px;
    transform: translateY(15%);
    margin: 0 auto;
  }
}

@-webkit-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@-moz-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

.section {
  &__title {
    display: flex;
    align-items: center;
    margin: 24px 0 20px;

    .icon {
      //width: 48px;
      //height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      margin-right: 6px;

      svg {
        display: block;
        width: 36px;
        height: 36px;
        fill: currentColor;
      }
    }
  }

  &-overview {
    p {
      max-width: 860px;
      font-size: 32px;
      line-height: 1.3;
      font-weight: 900;
    }
  }

  &-top {
    margin: 30px 0;
    background-image: linear-gradient(
            0deg,
            #fff 0%,
            rgba(179, 229, 252, 1) 60%,
            #fff 100%
    );
  }
}

.btn-buyMeCoffee {
  display: inline-block;
  min-width: auto;
  background: #ffdb00;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px;
  transition: opacity 0.3s;
}

.btn-buyMeCoffee:hover {
  opacity: 0.9;
}

.newsletter-form {
  position: relative;
  max-width: 400px;

  .btn-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: calc(0.4rem * 0.75) 12px !important;
    height: auto !important;
  }

  .input {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(0.4rem * 0.75) calc(0.875rem * 0.5);
      margin: calc(0.4rem * 0.75 + 3px) calc(0.5rem * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: #e3f2fd;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 2px solid currentColor;
      padding: calc(0.4rem * 1.5) 0.875rem;
      padding-right: 100px;
      color: currentColor;
      background: #e3f2fd;
      border-radius: 5px;

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.2rem, -65%) scale(0.8);
          color: #1976d2;
        }
      }
    }
  }
}

// substack iframe
.iframe-container {
  &-header {
    position: relative;
    display: inline-flex;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: #e3f2fd;
    }

    &:before {
      left: 0;
      width: 12px;
      height: 100%;
      @media (min-width: 768px) {
        width: 17px;
      }
    }

    &:after {
      right: 0;
      width: 17px;
      height: 100%;
    }

    .top,
    .bottom {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      background: #e3f2fd;
    }

    .top {
      top: 0;
      height: 16px;
    }

    .bottom {
      bottom: 0;
      height: 32px;
    }
  }
}
</style>

<script>
// import { default as latest } from "../data/sample-feed";
// import Parser from "rss-parser";
import axios from "axios";

export default {
  name: "Landing",

  mounted() {
    document.title = "The Coffee Break";

    let urlParams = new URLSearchParams(window.location.search);
    this.referral = urlParams.get("referral") || urlParams.get("ref") || "";

    this.retrieveFeed();
  },
  data() {
    return {
      busy: false,
      email: "",
      referral: "",
      errorMessage: "",
      errorBox: false,
      successBox: false,
      successDialog: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      rssFeeds: null,
      topPosts: [
        {
          title:
              "Meta (fka as Facebook) paid USD60 mil to acquire the Meta trademark assets from a US regional bank, Meta Financial Group",
          subtitle:
              "Feb deadline for Sinovac senior citizen recipients to obtain booster shots. Reddit, the front page of the Internet has filed for an IPO. Watch a spacecraft flying through the Sun's atmosphere.",
          date: "Fri, Dec 17 2021",
          imageUrl:
              "https://cdn.substack.com/image/upload/w_728,c_limit/zwwwgdr8m1p6buhjebyx",
          link: "https://thecoffeebreak.co/p/-meta-fka-as-facebook-paid-usd60",
        },
        {
          title:
              'British aeronautical engineer "very confident" to have identified MH370 plane location',
          subtitle:
              "Former PM Muhyiddin optimistic PN will be people's choice for GE15. Didi to delist in Nasdaq, relist in Hong Kong. Thai conglomerate Central Group to acquire Selfridges & Co for GBP4 bil.",
          date: "Mon, Dec 6 2021",
          imageUrl:
              "https://cdn.substack.com/image/youtube/w_728,c_limit/SBskBD4jbtE",
          link: "https://thecoffeebreak.co/p/-british-aeronautical-engineer-very",
        },
        {
          title:
              "LHDN soon can check your bank account(s) without your consent",
          subtitle:
              "ECF platform pitchIN hit RM200 mil milestone raised. Amazon Web Services to invest USD5 bil in Indonesia, creating 24.7k jobs. Japanese gov admitted to overstating country's economic growth figure.",
          date: "Thu, Dec 16 2021",
          imageUrl:
              "https://cdn.substack.com/image/youtube/w_728,c_limit/ywV5JMDHiAY",
          link: "https://thecoffeebreak.co/p/-lhdn-soon-can-check-your-bank-accounts",
        },
        {
          title: "Vegetable prices in Malaysia experiencing hyperinflation\n",
          subtitle:
              "Employees in MY are expected to see a real salary increase of 2.8% in 2022. Average of USD241 bil in nominal value of Tesla options traded daily. Sweden gets its first-ever female prime minister.",
          date: "Thu, Nov 25 2021",
          imageUrl:
              "https://cdn.substack.com/image/fetch/w_336,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F76a0a55c-bb9b-4f87-8d07-46213de3e743_627x680.jpeg",
          link: "https://thecoffeebreak.co/p/-vegetable-prices-in-malaysia-experiencing",
        },
      ],
      testimonials: [
        {
          source: "Twitter",
          content:
              "I spend a LOT of time online and I still miss half the news in this curated newsletter. Go subscribe to The Coffee Break!",
          user: "@surayaror",
          title: "Financial Blogger",
          twitterLink: "https://twitter.com/surayaror",
          avatar:
              "https://pbs.twimg.com/profile_images/1423096625975631874/LG1sVW3N_400x400.jpg",
        },
        {
          source: "Twitter",
          content:
              "Received my 2nd newsletter for @BreakMyCoffee over coffee. Love it, takes out all the noise in the news. Highly recommend you to subscribe to them & stay informed about latest news.",
          user: "@shannonchowz",
          twitterLink: "https://twitter.com/shannonchowz",
          avatar:
              "https://pbs.twimg.com/profile_images/1377946307461926912/xfHLhKI3_400x400.jpg",
        },
        {
          source: "Twitter",
          content: "Their newsletter is a great stuff seriously. Do subscribe!",
          user: "@bitcoinmalaya",
          twitterLink: "https://twitter.com/bitcoinmalaya",
          avatar:
              "https://pbs.twimg.com/profile_images/1469564380119400448/yOOthAoM_400x400.jpg",
        },
      ],
    };
  },
  computed: {
    firstTwoRssFeed() {
      if (this.rssFeeds && this.rssFeeds.length > 0) {
        return this.rssFeeds.slice(0, 3).map((feed) => {
          const content = feed.content.split('.');
          content.pop();
          feed.content = content;
          return feed;
        });
      }
      return null;
    },
  },
  methods: {
    gotoSupport() {
      this.$router.push({name: 'supportUs'});
    },
    async submitEmail() {
      this.busy = true;
      if (this.email === "") {
        this.errorBox = true;
        this.errorMessage = "Please key in your email";
        this.busy = false;
        return;
      }

      if (this.email.endsWith('.con')) {
        this.errorBox = true;
        this.errorMessage = "Email is invalid";
        this.busy = false;
        return;
      }

      try {
        this.$gtag.event('subscribe', {
          'event_label': 'subscribe',
          'value': this.email,
        });

        await axios.post(
            "https://hukopzhug3.execute-api.ap-southeast-1.amazonaws.com/production/email/submit",
            {
              email: this.email.trim(),
              referral: this.referral,
            }
        );
        // this.successBox = true;
        this.successDialog = true;
        // this.email = "";
        // this.referral = "";
        this.busy = false;
      } catch (err) {
        this.busy = false;
        this.errorMessage = "Something is not right, please try again later :(";
        this.errorBox = true;
      }
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    async retrieveFeed() {
      let feedUrl =
          "https://hukopzhug3.execute-api.ap-southeast-1.amazonaws.com/production/retrieve-top-feeds";
      // "https://qglljpzd97.execute-api.ap-southeast-1.amazonaws.com/dev/retrieve-top-feeds";
      const result = await axios.get(feedUrl);
      this.rssFeeds = result.data.result;
    },
  },
};
</script>
