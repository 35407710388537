<template>
  <div>
    <div class="landing-header blue lighten-5">
      <div class="arrow-vector">
        <div class="arrow-vector-inner">
          <div class="background-shapes__circle-filled"></div>
          <div class="arrow-shape">
            <img src="../assets/arrow.svg" alt=""/>
          </div>
        </div>
      </div>
      <v-container class="py-0 landing-header-inner">
        <v-row>
          <v-col
              cols="11"
              md="7"
              lg="6"
              class="d-flex align-center justify-center flex-column"
          >
            <div class="pt-6 px-4 pa-md-0">
              <h1 class="font-weight-black mb-3">
                <span class="d-inline-block animate-text font-weight-black"
                >Privacy Policy</span
                >
              </h1>
              <p
                  class="font-weight-regular blue-grey--text text--darken-4 headline"
              >

              </p>
            </div>
          </v-col>
          <v-col cols="12" md="5" lg="6">
            <div class="phone">
              <img src="../assets/phone.png" alt=""/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="section-overview mt-8 mt-md-14 mb-8">
      <v-container style="text-align: left">
        General Digital Sdn Bhd. (“GDSB” or “we”, “our” or “us”) operates The Coffee Break services, which includes a newsletter and a website (collectively, the “Services”).
        <br><br>
        We respect the privacy of users of the Services. This Privacy Policy supplements our Terms of Service and is intended to educate you on our policies and practices regarding the collection, use and disclosure of any Personal Information (defined below) and Anonymous Information (defined below).
        <br><br>
        Please review this Privacy Policy carefully. By using or accessing the Services, signing up for newsletters or subscribing to podcasts and/or communicating with us, you are accepting the terms of this Privacy Policy.
        <br><br>
        <h5>1. USER CONSENT/LEGAL BASIS</h5>

        “Personal Information” is information about you that is personally identifiable to you, like your name, address, and email address, as well as other non-public information that is associated with the foregoing. “Anonymous Information” is information that is not associated with or linked to your Personal Information; Anonymous Information does not allow the identification of individual persons. We collect and use Personal Information and Anonymous Information as indicated in this Privacy Policy.
        <br><br>
        When you submit any Personal Information through the Services, you agree to the terms of this Privacy Policy and you expressly consent to the treatment of your Personal Information defined in this Privacy Policy.
        <br><br>
        Other legal basis for collecting Personal Information are: (i) where we need the Personal Information for performance of a contract or requested service; or (ii) where the collection and use is in our or another’s legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect the Personal Information in question. If we collect your Personal Information with your consent, you may have the right to withdraw your consent at any time  by contacting us as per below.
        <br><br>


        <h5>2. INFORMATION COLLECTED</h5>
        We may collect Personal Information that you submit to us directly through the Services, and via passive technologies integrated into the Services, including via third parties.
        <br><br>
        You may directly submit information to us when you subscribe to a newsletter or podcast, order a product (such as our paid products), purchase merchandise, answer survey questions, or use other methods of submitting information. The types of information you can choose to provide on the Services includes:
        <br><br>
        <ul>
        <li>Name, email address, zip/postal code, physical location information and contact information.</li>
        <li>Billing and shipping addresses, credit card number, and expiration date.</li>
        <li>Personal Information you choose to provide, including your age, gender, race/ethnicity, job title and role, industry, and employer information.</li>
        </ul>
        <br>
        The Services also passively collect certain types of Personal Information and Anonymous Information, including through third parties. We may use pixel tags, cookies, and/or other similar technologies to collect this information. We do not use any such technologies to conduct online targeted advertising
        <br><br>
        Please also note that most web browsers automatically accept cookies and other tracking technologies, but if you prefer, you may be able to edit your browser options to block them. Visitors to the Services who disable cookies will be able to browse most areas of the Services, but some features may not function. You can find out more information about how to change your browser cookie settings at www.allaboutcookies.org. Further, the Services do not currently recognize “Do Not Track” settings or technologies.
        <br><br>
        <h5>3. USE OF INFORMATION</h5>
        We may use your Personal Information for the following general and business purposes:
        <ul>
          <li>To deliver Services.</li>
          <li>To fulfill or respond to your inquiries.</li>
          <li>To address administrative needs and communications.</li>
          <li>To improve Services.</li>
          <li>To contact you for internal business purposes or for marketing communications purposes. You may always choose not to receive marketing communications from GDBS  by following the unsubscribe instructions set out in our communications.
          </li>
          <li>To provide notice of changes and upgrades to Services.</li>
        </ul>
        <br><br>
        We may use both manual and automated systems, such as machine learning and artificial intelligence, to: analyze Personal Information and Anonymous Information to improve our Services; personalize your experience; automate certain aspects of the Services; aggregate and anonymize information; provide you certain features; and protect the safety and security of our Services.
        <br>
        We use Anonymous Information such as web or mobile pages you have viewed, in order to analyze request and usage patterns so that we may improve our products and services, enhance our user experience, and gather broad demographic information for aggregate use.
        <br><br>
        <h5>4. INFORMATION SHARING</h5>
        We do not sell, trade, rent, or share your Personal Information with third parties for their marketing purposes, and we will not disclose financial information that is part of your Personal Information, in each case unless you request or authorize that we do so.
        <br><br>
        <ul>
        <li>i. Affiliated Entities
        We may disclose Personal Information to our corporate subsidiaries or affiliated entities which operate under our same internal processes and policies. Any Personal Information provided to our subsidiaries or entities affiliated with us will be treated by those subsidiaries and affiliated entities in accordance with the terms of this Privacy Policy.
        </li>
          <li>
        ii. Service Providers
        We may share your Personal Information with third party vendors and suppliers that help us provide our Services to you. These third parties provide services to us, such as email delivery services (for instance, to send you newsletters), data hosting, customer relationship management, and data analytics. You expressly consent to the sharing of your Personal Information with our contractors and third-party service providers (for the sole purpose of providing products and services to you. These service providers are contractually obligated to ensure the confidentiality of Personal Information and implement appropriate security measures.
          </li>
        <li>
          iii. Payment Processors
        We use third-party payment processors for any purchases you make through the Services. You can read more about how Stripe uses your Personal Information here: https://stripe.com/privacy. Be advised that we do not have access to any Personal Information, including credit card numbers or other financial information, you provide to these processors. This Privacy Policy does not apply to them, and we do not accept any responsibility or liability for their policies.  We encourage you to review the privacy policies of these third-party providers.
          </li>
          <li>
        iv. Partner Companies
        We may participate in co-branding or co-promotional agreements with third parties pursuant to which we may share Personal Information with such third parties (“Partner Companies”). For example, we may participate in agreements with Partner Companies pursuant to which we provide the Partner Companies with a URL and a customer registration page co-branded with, or private labeled by, the Partner Companies, and the Partner Companies distribute and promote the URL to its customers. A Partner Company may have access to Personal Information that we collect from their customers. As a result, if you register for the Services through a Partner Company, we may provide your Personal Information to the Partner Company. We do not control the privacy practices of these Partner Companies and we encourage you to review their privacy policies.
        Under the following scenarios, we may be required or authorized by law to share your Personal Information:
            <ul>
              <li>If we believe it is necessary to share information in order to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving possible threats to the physical safety of any person, violations of our Terms of Use or as otherwise required by law.</li>
              <li>If we respond to subpoenas, court orders or legal process, or if we need to establish or exercise our legal rights or defend against legal claims.</li>
              <li>If we believe it is necessary to restrict or inhibit any user from using any of the Services, including, without limitation, by means of “hacking” or defacing any portion thereof.</li>
            </ul>
          </li>
        </ul>
        <br>
        We may use your Personal Information in order to provide to third parties non-personal aggregated information that does not allow you to be identified or contacted and that is combined with the Personal Information of other users (“Aggregate Information”). For example, we might inform third parties regarding the number of users of the Services and the activities they conduct while on the Services. We might also inform a company that performs services or that provides products and/or services to us (that may or may not be a business partner or an advertiser on the Services) that “50% of our users live in the Malaysia” or that “85% of our users have purchased products and/or services which can be downloaded from the Services”. Depending on the circumstances, we may or may not charge third parties for this Aggregate Information. We may not limit the third parties’ use of the Aggregate Information.
        <br><br>
        We may disclose Anonymous Information to third parties that promote our products and services. We reserve the right to use and disclose Anonymous Information to third parties at our discretion.
          <br><br>
        <h5>5. OPT-OUT OF MARKETING EMAILS OR NEWSLETTERS</h5>
        When you receive promotional communications or newsletters from us, you may “opt-out” by following the unsubscribe instructions indicated in the communication you receive.. We may continue to send you communications regarding the Services, such as notices about administrative updates, transaction reports, and changes to the Services, this Privacy Policy or the Terms of Service. Where applicable, please refer to our Privacy Notice.
        <br><br>
        <h5>6. SECURITY</h5>
        GDSB has implemented technical, administrative and physical security measures to protect your information from loss or theft, as well as unauthorized access, use or disclosure. While we strive to protect your information, no data transmission over the Internet is 100% secure and, consequently, we cannot guarantee or warrant the security of any information you provide, and you do so at your own risk. We cannot promise that your information will remain absolutely secure in all circumstances. We are not responsible for the circumvention of any privacy settings or security measures we may provide.
        We will retain your information for the time necessary to provide a requested service, realize our legitimate business purposes and to comply with the law. We will only retain personal information for the longer of the period if: (a) required by law, or (b) there is a time-bound determination business need.
        <br><br>
        <h5>7. CHILDREN’S INFORMATION</h5>
        The Services are not intended to be used by minors under the age of 18, and we will not knowingly collect Personal Information from children under these age (as applicable). If you are under 18 years old or the age of majority in your jurisdiction of residence (whichever is higher), you will require the consent of your parent or legal guardian to agree to your use of the Services and to provide your Personal Information to us. We do not use an application or other mechanism to determine the age of users of the Services. All information provided to GDSB will be treated as if it was provided by an adult. If, however, we learn that a child has submitted information about himself/herself to us, we will delete the information as soon as possible.
        <br><br>
        <h5>8. LINKS TO THIRD-PARTY WEBSITES</h5>
        The Services may contain links to and from third party websites of our business partners and various contact management services and other service providers. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check their individual privacy policies before you submit any Personal Information to those websites.
        Our provision of a link to any other website or location is for your convenience and does not indicate our endorsement of such other website or location or its contents. We have no control over, do not review, and cannot be responsible for these outside websites or their content. Please be aware that the terms of our Privacy Policy do not apply to these outside websites.
        <br><br>
        <h5>9. UPDATES TO PRIVACY POLICY</h5>
        We may modify this Privacy Policy from time to time by posting updates on this page. If we make any significant changes to this Privacy Policy, we will contact you to inform you when required by law.
        <br><br>
        <h5>10. CONTACT US</h5>
        Should you have any questions, concerns or feedback regarding this Privacy Policy, please contact us by email at breakmycoffee (@) gmail.com.

      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$leading: (
    "none": 1,
    "tight": 1.25,
    "snug": 1.375,
    "normal": 1.5,
    "relaxed": 1.625,
    "loose": 2,
);

.testi-links {
  color: #00d4ff;
}

.v-application .caption {
  font-size: medium !important;
}

@each $name, $value in $leading {
  .leading-#{$name} {
    line-height: $value !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1185px !important;
  }
}

.v-card__text__height {
  line-height: 1.5em;
  min-height: 7em;
}

.v-card__title {
  word-break: break-word;
}

.content-card {
  word-break: keep-all;

  figure {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.05) linear-gradient(
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0) 20%,
              rgba(0, 0, 0, 0.85) 100%
      );
      transition-property: all;
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
  }

  &:hover {
    figure {
      &:after {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.landing-header {
  position: relative;
  overflow: hidden;

  &-inner {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 58px;
    line-height: 1;
    @media only screen and (min-width: 600px) {
      font-size: 64px;
      line-height: 1.2;
    }
  }

  p {
    font-size: 21px;
  }

  .animate-text {
    background: linear-gradient(231deg, #083d73, #094879, #00d4ff);
    background-size: 600% 600%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-animation: TextGradientAnimation 5s ease infinite;
    -moz-animation: TextGradientAnimation 5s ease infinite;
    animation: TextGradientAnimation 5s ease infinite;
  }
}

.arrow-vector {
  position: absolute;
  width: 100%;
  max-width: 1185px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  &-inner {
    -webkit-animation: fadein 1s;
    animation: fadein 1s;
    display: grid;
    grid-column: 1/-1;
    grid-row: 1/-1;
    grid-template-areas:
      "topleft topcenter topright"
      "middleleft middlecenter middleright"
      "bottomleft bottomcenter bottomright";
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .arrow-shape {
    position: absolute;
    width: 500px;
    bottom: 0;
    z-index: 1;
    transform: rotate(-15deg);
    right: 5px;

    @media only screen and (min-width: 600px) {
      width: 1000px;
      right: 15px;
    }
  }

  .background-shapes__circle-filled {
    background-color: #0093e9;

    background-image: linear-gradient(
            160deg,
            #0093e9 0%,
            rgba(99, 251, 215, 1) 50%,
            #80d0c7 100%
    );
    mix-blend-mode: multiply;

    border-radius: 100%;
    position: relative;
    height: 590px;
    width: 590px;
    transform: translate3d(-30%, -10%, 0);
    grid-area: bottomcenter;
    @media only screen and (min-width: 600px) {
      height: 1090px;
      width: 1090px;
      transform: translate3d(-25%, -20%, 0);
      grid-area: bottomright;
    }
  }
}

.phone {
  max-width: 230px;
  margin: 30px auto;

  img {
    display: block;
    width: 100%;
  }

  @media only screen and (min-width: 600px) {
    max-width: 300px;
    transform: translateY(15%);
    margin: 0 auto;
  }
}

@-webkit-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@-moz-keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

@keyframes TextGradientAnimation {
  0% {
    background-position: 0% 88%;
  }
  50% {
    background-position: 100% 13%;
  }
  100% {
    background-position: 0% 88%;
  }
}

.section {
  &__title {
    display: flex;
    align-items: center;
    margin: 24px 0 20px;

    .icon {
      //width: 48px;
      //height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      margin-right: 6px;

      svg {
        display: block;
        width: 36px;
        height: 36px;
        fill: currentColor;
      }
    }
  }

  &-overview {
    text-align: center;

    p {
      max-width: 700px;
      margin: 0 auto 18px;
      font-size: 16px;
      line-height: 1.6;
      @media (min-width: 768px) {
        font-size: 21px;
        margin: 0 auto 24px;
      }
    }
  }


  &-top {
    margin: 30px 0;
    background-image: linear-gradient(
            0deg,
            #fff 0%,
            rgba(179, 229, 252, 1) 60%,
            #fff 100%
    );
  }
}

.newsletter-form {
  position: relative;
  max-width: 400px;

  .btn-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: calc(0.4rem * 0.75) 12px !important;
    height: auto !important;
  }

  .input {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(0.4rem * 0.75) calc(0.875rem * 0.5);
      margin: calc(0.4rem * 0.75 + 3px) calc(0.5rem * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: #e3f2fd;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 2px solid currentColor;
      padding: calc(0.4rem * 1.5) 0.875rem;
      padding-right: 100px;
      color: currentColor;
      background: #e3f2fd;
      border-radius: 5px;

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.2rem, -65%) scale(0.8);
          color: #1976d2;
        }
      }
    }
  }
}

// substack iframe
.iframe-container {
  &-header {
    position: relative;
    display: inline-flex;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: #e3f2fd;
    }

    &:before {
      left: 0;
      width: 12px;
      height: 100%;
      @media (min-width: 768px) {
        width: 17px;
      }
    }

    &:after {
      right: 0;
      width: 17px;
      height: 100%;
    }

    .top,
    .bottom {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      background: #e3f2fd;
    }

    .top {
      top: 0;
      height: 16px;
    }

    .bottom {
      bottom: 0;
      height: 32px;
    }
  }
}

.sns {
  width: 100%;
  //max-width: 700px;
  margin: 32px auto 0;
  padding: 32px 16px;
  background: rgba(0, 0, 0, .1);
  //border-top: 1px solid rgba(0, 0, 0, .2);
  //border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.btn-sns {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  line-height: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  font-size: 16px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  margin: 5px 0;
  transition: opacity .3s;
}

.btn-sns:hover {
  opacity: .9;
}

.btn-facebook {
  background: rgb(24, 119, 242);
  margin-right: 10px;
}

.btn-twitter {
  background: #1DA1F2;
}
</style>

<script>
export default {
  name: "PrivacyPolicy",

  mounted() {
    document.title = "The Coffee Break";
  },
  data() {
    return {
      busy: false,
      email: "",
      errorMessage: "",
      errorBox: false,
      successBox: false,
      successDialog: false,
      rssFeeds: null,
    };
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
