<template>
  <v-app>
    <v-app-bar elevation="0" class="appBar blue lighten-5">
      <div class="d-flex align-center logo-container" @click="gotoPage('home')">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="./assets/tcb-full-logo.png"
          transition="scale-transition"
          width="70"
          height="120"
        />
        <span class="logo-text">TheCoffeeBreak </span>
      </div>

      <v-spacer></v-spacer>
      <div class="d-md-none d-sm-block d-lg-none d-xl-none">
      <v-menu
          top
          :close-on-content-click="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Menu
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-btn
                class="btn-support-us"
                @click="gotoPage('supportUs')"
            >
              Support Us
            </v-btn>
          </v-list-item>
<!--          <v-list-item>-->
<!--            <v-list-item-title @click="onClickManageAccount">Manage TCB Account</v-list-item-title>-->
<!--          </v-list-item>-->
          <v-list-item>
            <v-list-item-title @click="onClickManageMMTVAccount">Manage Account</v-list-item-title>
          </v-list-item>
          <v-list-item
              v-for="(item, index) in items"
              :key="index"
          >
            <v-list-item-title @click="gotoPage(item.pageName)">{{ item.title }}</v-list-item-title>
          </v-list-item>

        </v-list>
      </v-menu>
      </div>
<!--      <v-btn-->
<!--        color="blue darken-4"-->
<!--        class="white&#45;&#45;text mr-1 d-none d-md-block"-->
<!--        style="border-width: 2px"-->
<!--        @click="gotoPage('supportUs')"-->
<!--      >-->
<!--        Support Us-->
<!--      </v-btn>-->
      <v-btn
          color="grey darken-4"
          class="ml-sm-2 white--text d-none d-md-block"
          style="border-width: 2px"
          @click="gotoPage('about')"
      >
        About Us
      </v-btn>
      <v-btn
          class="ml-sm-2 white--text d-none d-md-block btn-support-us"
          style="border-width: 2px"
          @click="gotoPage('supportUs')"
      >
        Support Us
      </v-btn>
      <v-btn
          color="grey darken-4"
          class="ml-sm-2 white--text d-none d-md-block"
          style="border-width: 2px"
          @click="gotoPage('career')"
      >
        Career
      </v-btn>
      <v-btn
          color="grey darken-4"
          class="ml-sm-2 white--text d-none d-md-block"
          style="border-width: 2px"
          @click="gotoPage('termsConditionsReferral')"
      >
        Referral
      </v-btn>
<!--      <v-btn-->
<!--          color="transparent darken-4"-->
<!--          class="ml-sm-2 black&#45;&#45;text mr-1 d-none d-md-block"-->
<!--          style="border-width: 2px"-->
<!--          @click="onClickManageAccount"-->
<!--      >-->
<!--        Manage Account-->
<!--      </v-btn>-->
      <v-btn
          color="transparent darken-4"
          class="ml-sm-2 black--text mr-1 d-none d-md-block"
          style="border-width: 2px"
          @click="onClickManageMMTVAccount"
      >
        Manage Account
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer class="footer py-12 py-md-16 mt-8 mt-md-12" color="white">
      <v-container>
        <v-row>
          <v-col>
            <div class="logo">
              <img src="./assets/tcb-logo-horizontal.png" alt="" />
            </div>
            <div class="caption blue-grey--text mt-4">
              <div><a @click="gotoPage('privacyPolicy')">Privacy Policy</a></div>
              <div><a @click="gotoPage('termsOfService')">Terms Of Service</a></div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-md-right mt-4 mt-md-0">
              <div class="title font-weight-bold">
                Makes you smarter in 3 minutes.
              </div>
              <div class="text-body-2">
                Get the daily email that makes reading the news actually
                enjoyable. Stay informed and entertained, for free.
              </div>

              <div class="iframe-container-footer mt-2">
                <iframe
                  src="https://thecoffeebreak.substack.com/embed"
                  width="340"
                  height="100"
                  frameborder="0"
                  scrolling="no"
                ></iframe>
              </div>
              <div class="caption">
                Copyright © 2023 GENERAL DIGITAL SDN BHD (1454715-T)
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>
<style lang="scss" scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px !important;
  }
}
.logo-container {
  cursor: pointer;
}
.logo-text {
  font-size: larger;
}

.btn-support-us {
  background-color: #7cb701 !important;
  color: white !important;
}

.v-btn {
  //text-transform: capitalize;
  //letter-spacing: normal;
}

.appBar {
  .v-btn {
    height: 40px;
    font-weight: bold;
  }
}

.footer {
  //min-height: 100px;
  //background-color: #1976d2;
  border-top: 1px solid #dfe1e6 !important;

  .logo {
    width: 240px;

    img {
      display: block;
      width: 100%;
    }
  }
}

.newsletter-form {
  position: relative;
  max-width: 400px;

  .btn-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: calc(0.4rem * 0.75) 12px !important;
    height: auto !important;
  }

  .input {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(0.4rem * 0.75) calc(0.875rem * 0.5);
      margin: calc(0.4rem * 0.75 + 3px) calc(0.5rem * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: white;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 2px solid currentColor;
      padding: calc(0.4rem * 1.5) 0.875rem;
      padding-right: 100px;
      color: currentColor;
      background: transparent;
      border-radius: 5px;

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.2rem, -65%) scale(0.8);
          color: #1976d2;
        }
      }
    }
  }
}

// substack iframe
.iframe-container {
  &-footer {
    transform: translateX(-15px);
    @media (min-width: 960px) {
      transform: translateX(15px);
    }
  }
}
</style>
<script>
import Landing from "./components/Landing";
import router from "./router";

export default {
  name: "App",
  metaInfo: {
    title: "The Coffee Break",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { name: "description", content: "Malaysian's favourite Newsletter" },
      { charset: "utf-8" },
      { property: "og:title", content: "The Coffee Break" },
      { property: "og:url", content: "https://thecoffeebreak.co" },
      {
        property: "og:description",
        content: "Malaysian's favourite Newsletter",
      },
      {
        property: "og:image",
        content: "https://thecoffeebreak.co/tcb-share.png",
      },
    ],
  },
  components: {
    Landing,
  },
  data: () => ({
    items: [
      { title: 'Career', pageName: 'career' },
      { title: 'About', pageName: 'about' },
    ],
  }),
  methods: {
    onClickManageAccount() {
      // const url = 'https://billing.stripe.com/p/login/test_7sI8zQb50ckqgqQ9AA'; // test mode link
      // const url = 'https://billing.stripe.com/p/login/00geWkdsra5meCAeUU';
      const url = 'https://billing.stripe.com/p/login/dR6dSQ3TddNSg5a288';
      window.open(url, '_blank', 'noreferrer');
    },
    onClickManageMMTVAccount() {
      // const url = 'https://billing.stripe.com/p/login/test_7sI8zQb50ckqgqQ9AA'; // test mode link
      // const url = 'https://billing.stripe.com/p/login/00geWkdsra5meCAeUU';
      const url = 'https://billing.stripe.com/p/login/14k5mT1L4aFi6TC8ww';
      window.open(url, '_blank', 'noreferrer');
    },
    gotoPage(page) {
      router.push({ name: page });
    },
  },
};
</script>
