import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import router from "./router";

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(VueGtag, {
  config: {
    id: "UA-198729275-1",
    params: {
      send_page_view: true,
    },
  },
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
